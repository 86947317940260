import {ThumbnailSize} from '../../../../shared/models/thubmbnail-size.model';

export const DEFAULT_THUMBNAIL_SIZE_ID = 3;

export const THUMBNAIL_SIZES: Map<number, ThumbnailSize> = new Map([
  [0, {title: 'XS', value: 'xs', minSize: 66, maxSize: 73}],
  [1, {title: 'S', value: 's', minSize: 108, maxSize: 125}],
  [2, {title: 'M', value: 'm', minSize: 156, maxSize: 189}],
  [3, {title: 'L', value: 'l', minSize: 204, maxSize: 275}],
  [4, {title: 'XL', value: 'xl', minSize: 270, maxSize: 363}],
]);

export enum ZoomThumbnailsState {
  DOWN = -1,
  UP = 1,
}
