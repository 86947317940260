<div class="subscription-manager">
  @if (!!(subscriptionActivationWaitingFacade.isWaitingShown$ | async)) {
    <px-subscription-activation-waiting-dialog
      [isWaitingSubscription]="!!(subscriptionActivationWaitingFacade.isWaiting$ | async)"
      (subscriptionActivatedActionClick$)="handleSubscriptionActivatedActionClick()"
    />
  }

  <px-pricing-dialog
    [title]="title"
    [sale10]="sale10"
    [description]="description"
    [subscribedProductId]="subscribedProductId"
    [hidden]="!!(subscriptionActivationWaitingFacade.isWaitingShown$ | async)"
    [highlightPlan]="highlightPlan"
    [highlightPlanTitle]="highlightPlanTitle"
    [highlightPButtonText]="highlightPButtonText"
    (checkoutSuccess$)="handleCheckoutSuccess($event)"
    (checkoutError$)="handleCheckoutError($event)"
    (checkoutOpen$)="handleCheckoutOpen$($event)"
    (upgradeSuccess$)="handleUpgradeSuccess($event)"
    (subscriptionActivationState$)="handleSubscriptionActivationState($event)"
    (close$)="close()"
  />
</div>
