import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../shared/store/app/app.state';
import {tap} from 'rxjs/operators';
import {MainPageRedirect} from '../../auth/store/auth.actions';
import {Injectable} from '@angular/core';
import {BrowserService} from '@px/shared/browser';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';

@Injectable()
export class UnsupportedBrowserGuard {
  get isBrowserSupport(): boolean {
    return this.browserService.satisfies(this.platform.LATEST_BROWSERS_VER);
  }

  constructor(
    private store$: Store<AppState>,
    private readonly browserService: BrowserService,
    private readonly platform: PSSPlatformEnvironment
  ) {}

  canActivate(): Observable<boolean> {
    return of(!this.isBrowserSupport).pipe(
      tap((isNotSupport: boolean) => !isNotSupport && this.store$.dispatch(MainPageRedirect()))
    );
  }
}
