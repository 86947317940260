<div class="mobile-page-wrapper" layout="column" layout-align="center center">
  <div class="logo-wrapper">
    <a [routerLink]="redirectUrl" class="icon-logo"><ng-content select="logo" /></a>
  </div>
  <div class="center-container text-center" layout="column" layout-align="center center">
    @for (description of descriptions; track $index) {
      <div class="text-line">{{ description }}</div>
    }
    <div class="link-separator"></div>
    @if (redirectUrlText) {
      <a class="px-link" [routerLink]="redirectUrl">{{ redirectUrlText }}</a>
    }
  </div>
  <div>
    <a [href]="companyUrl" style="display: inline-block; color: #373939">
      <mds-icon icon="caPixelluLogo" />
    </a>
  </div>
</div>
