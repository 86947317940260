<div
  class="tooltip"
  [class.tooltip--w-small]="width === 'small'"
  [class.tooltip--w-medium]="width === 'medium'"
  [class.tooltip--w-large]="width === 'large'"
  [class.tooltip--w-x-large]="width === 'xLarge'"
  [class.tooltip--w-auto]="width === 'auto'"
  [class.tooltip--w-nowrap]="width === 'nowrap'"
  [class.tooltip--wrap-balancer]="wrapBalancer"
  data-qa="tooltip-container"
  [@puiFadeInOut]="animationState"
  (@puiFadeInOut.start)="onAnimationStart($event)"
  (@puiFadeInOut.done)="onAnimationDone($event)"
>
  <div class="tooltip__inner" data-qa="tooltip-container-content">
    @if (asString; as string) {
      <span [innerText]="string"></span>
    }
    @if (asTemplate; as template) {
      <ng-template [ngTemplateOutlet]="template" />
    }
  </div>
</div>
