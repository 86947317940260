import {AnimationEvent} from '@angular/animations';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  booleanAttribute,
  inject,
} from '@angular/core';
import {puiFadeInOut} from '@pui/cdk/animations';
import {MdsWrapBalancerComponent} from '@pui/cdk/wrap-balancer';
import {puiTooltipData, puiTooltipType, puiTooltipWidth} from '../tooltip.types';

@Component({
  selector: 'pui-tooltip',
  standalone: true,
  imports: [NgIf, NgClass, NgTemplateOutlet, MdsWrapBalancerComponent],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [puiFadeInOut],
})
export class PuiTooltipComponent {
  private readonly cdr = inject(ChangeDetectorRef);

  @Input() type: puiTooltipType = 'base';
  @Input() width: puiTooltipWidth = 'medium';
  @Input() animationState: 'void' | 'enter' | 'exit' = 'enter';
  @Input({transform: booleanAttribute}) wrapBalancer = false;
  @Input() tooltip?: puiTooltipData;

  @Output() animationStateChanged$ = new EventEmitter<AnimationEvent>();
  @Output() resize$ = new EventEmitter<void>();

  get asString(): string | boolean {
    return typeof this.tooltip === 'string' ? this.tooltip : false;
  }

  get asTemplate(): TemplateRef<void> | null {
    return this.tooltip instanceof TemplateRef ? this.tooltip : null;
  }

  onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged$.emit(event);
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationStateChanged$.emit(event);
  }

  startExitAnimation(): void {
    this.animationState = 'exit';
    this.cdr.markForCheck();
  }

  startOpenAnimation(): void {
    this.animationState = 'enter';
    this.cdr.markForCheck();
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }
}
