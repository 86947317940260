import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';

import * as fromGlobal from '../global/global.state';
import * as fromAuth from '../../../auth/store/auth.state';
import * as fromManager from '../../../main/manager/store/IManagerState';
import * as fromPreferences from '../../../main/preferences/store/preferences.state';
import * as fromSlideshow from '../../../main/store/slideshow/slideshow.state';
import * as fromVideoRendering from '../../../main/store/video-rendering/video-rendering.state';
import * as fromSlideshowOptions from '../../../main/store/slideshow-options/slideshow-options.reducer';
import * as fromShareDialog from '../../../main/shared/components/share-dialog/store/share-dialog.reducer';
import {environment} from '../../../../environments/environment';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
  globals: fromGlobal.GlobalState;
  auth: fromAuth.AuthState;
  manager: fromManager.IManagerState;
  preferences: fromPreferences.PreferencesState;
  router: RouterReducerState;
  shareDialog: fromShareDialog.ShareDialogState;
  slideshow: fromSlideshow.SlideshowStates;
  slideshowOptions: fromSlideshowOptions.State;
  videoRendering: fromVideoRendering.IVideoRenderingState;
}

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ST = state;
    return reducer(state, action);
  };
}

export const ROOT_REDUCER: ActionReducerMap<Partial<AppState>> = {
  router: routerReducer,
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const USER_PROVIDED_META_REDUCERS: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
