import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AbTestsModule} from '../shared/angular-ab-tests/module';
import {globalStateReducer} from '../shared/store/global/global.reducer';
import {GlobalEffects} from '../shared/store/global/global.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('globals', globalStateReducer),
    EffectsModule.forFeature([GlobalEffects]),
    AbTestsModule.forRoot([
      {
        versions: ['old', 'new'],
        // weights: { A: 50, B: 50 }
      },
    ]),
  ],
})
export class CoreModule {}
