import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MdsButtonComponent} from '@pui/components/button';
import {MdsIconComponent} from '@pui/components/icon-core';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import {UiButtonModule} from '@px/ui-components-px-button';
import {ManagerSortComponent} from './components/manager-sort/manager-sort.component';
import {SlideshowNameComponent} from './components/slideshow-name/slideshow-name.component';
import {SurveyContentComponent} from './components/survey-content/survey-content.component';
import {SurveyDialogComponent} from './components/survey-dialog/survey-dialog.component';
import {UpgradeReminderComponent} from './components/upgrade-reminder/upgrade-reminder.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    UiButtonModule,
    PuiTooltipDirective,
    MdsButtonComponent,
    MdsIconComponent,
    SlideshowNameComponent,
  ],
  declarations: [ManagerSortComponent, UpgradeReminderComponent, SurveyDialogComponent, SurveyContentComponent],
  exports: [
    ManagerSortComponent,
    SlideshowNameComponent,
    UpgradeReminderComponent,
    SurveyDialogComponent,
    SurveyContentComponent,
  ],
})
export class FeaturePageManagerModule {}
