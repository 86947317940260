import {inject, NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth.effects';
import {authReducers} from './store/auth.reducer';
import {AUTH_REPOSITORY_TOKEN} from '@px/pss/auth';
import {AuthRepositoryService} from './services/auth-repository.service';
import {AuthApiService} from './services/auth-api.service';
import {IPSSAuthService} from './models/auth-service.interface';
import {PSSPlatformEnvironment, PSSPlatformFeatures} from '@px/pss/platform-env';
import {PSSAuthFacade} from './services/pss-auth-facade.service';
import {AUTH_SERVICE} from '@px/shared/api';
import {CheckAuthGuard} from './guards/check-auth.guard';

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducers), EffectsModule.forFeature([AuthEffects])],
  providers: [
    CheckAuthGuard,
    {
      provide: AuthApiService,
      useFactory: (): IPSSAuthService => {
        const platform = inject(PSSPlatformEnvironment);
        return platform.hasFeature(PSSPlatformFeatures.AUTHORIZATION_GRAPHQL_API)
          ? new PSSAuthFacade()
          : new AuthApiService();
      },
    },
    {
      provide: AUTH_SERVICE,
      useExisting: AuthApiService,
    },
    {provide: AUTH_REPOSITORY_TOKEN, useClass: AuthRepositoryService},
  ],
})
export class AuthModule {}
