import {Languages, PlatformEnvironment} from '@px/shared/env';
import {TranslateCompiler, TranslateLoader} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from '@px/shared/translate-message-format-compiler';
import {HttpClient} from '@angular/common/http';
import {TranslateModuleConfig} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export const createTranslateLoader = (
  http: HttpClient,
  platformEnvironment: PlatformEnvironment
): TranslateHttpLoader => {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/frontend_locale-',
    `.json?${platformEnvironment.getQueryString()}`
  );
};

export const translateModuleConfig: TranslateModuleConfig = {
  defaultLanguage: Languages.en,
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
  },
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient, PlatformEnvironment],
  },
};
