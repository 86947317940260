import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IBillingProductClientFeature} from '@ps/pricing-domain';

@Component({
  selector: 'px-plan-feature',
  templateUrl: './plan-feature.component.html',
  styleUrls: ['./plan-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PlanFeatureComponent {
  @Input() feature?: IBillingProductClientFeature;
}
