import {Injectable} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import type {Dict, Mixpanel} from 'mixpanel-browser';
import {BehaviorSubject} from 'rxjs';
import {UserProps} from '../interfaces/user-props';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private isEnabledInternal = false;

  private mixpanel: Mixpanel | undefined;

  isEnabled$ = new BehaviorSubject<boolean>(this.isEnabledInternal);

  private get isEnabled(): boolean {
    return this.isEnabledInternal;
  }

  private set isEnabled(value: boolean) {
    this.isEnabledInternal = value;
    this.isEnabled$.next(value);
  }

  private get isAvailable(): boolean {
    return this.isEnabled && !!this.mixpanel?.people;
  }

  constructor(private readonly platform: PlatformEnvironment) {}

  async init(): Promise<void> {
    if (this.platform.MIXPANEL_ENABLED && !this.platform.hasFeature('DISABLE_OLD_MIXPANEL')) {
      try {
        const {default: module} = await import('mixpanel-browser');
        this.mixpanel = module.init(
          this.platform.MIXPANEL_TOKEN,
          // we are ignoring dnt because we are using user.no_tracking for that thing
          {debug: this.platform.DEBUG, ignore_dnt: true},
          this.platform.APP_NAME
        );

        this.isEnabled = true;
      } catch (e) {
        console.error((e as Error).name + ': ' + (e as Error).message);
        this.isEnabled = false;
      }
    }
  }

  track(id: string, action: Dict = {}): void {
    try {
      if (this.isAvailable) {
        this.mixpanel?.track(id, action);
      } else {
        console.warn('Mixpanel is not available');
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  setSuperProperties(properties: Dict): void {
    try {
      if (this.isAvailable) {
        this.mixpanel?.register(properties);
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  setUserProperties(properties: Dict & Partial<UserProps>): void {
    try {
      if (this.isAvailable) {
        this.mixpanel?.people.set(properties);
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  incrementUserProperties(property: string, by = 1): void {
    try {
      if (this.isAvailable) {
        this.mixpanel?.people.increment(property, by);
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  identify(userId?: string | number): void {
    try {
      this.mixpanel?.reset();

      if (this.isAvailable) {
        this.mixpanel?.identify(userId?.toString());
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  destroy(): void {
    try {
      if (this.isAvailable) {
        this.mixpanel?.reset();
      }
    } catch (e) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }
}
