<div class="pricing-dialog pricing-dialog--base" [hidden]="hidden">
  <div class="pricing-dialog__header">
    <button (click)="close$.emit()" class="pricing-dialog__close-button">
      <mds-icon icon="pxClose" />
    </button>
  </div>
  <div class="pricing-dialog__body">
    @if (title && description) {
      <header class="pricing-dialog__body-header">
        <h2>{{ title }}</h2>
        <p>{{ description }}</p>
      </header>
    }
    <div class="pricing-dialog__content">
      <px-pricing
        [highlightPlan]="highlightPlan"
        [sale10]="sale10"
        [highlightPlanTitle]="highlightPlanTitle"
        [highlightPButtonText]="highlightPButtonText"
        (px-pricing-checkout-success)="handleCheckoutSuccess($event)"
        (px-pricing-checkout-error)="checkoutError$.emit($event)"
        (px-pricing-checkout-closed)="handleCheckoutClosed()"
        (px-pricing-checkout-open)="handleCheckoutOpen($event)"
        (px-pricing-upgrade-success)="handleUpgradeSuccess($event)"
        (px-pricing-subscription-activation-state)="subscriptionActivationState$.emit($event)"
      />
    </div>
  </div>
</div>
