import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppSelectors} from '../../../shared/store/app/app.selectors';

import {photoAdapter, segmentAdapter} from './slideshow.adapters';
import {
  checkSlideshowError,
  countTrimPoint,
  getActiveTemplatesForSegment,
  getSlideshowPreviewUrl,
} from './slideshow-utils';
import {PhotoState, Segment, SegmentState} from '../../../shared/models/slideshow.model';
import {PreferencesSelectors} from '../../preferences/store/preferences.selectors';
import {TPPData, TPPPrimaryBtn, TPPPrimaryBtnType} from '../../editor/components/image-area/tpp/models';
import {Dictionary} from '@ngrx/entity';
import {ExtractField, ExtractNullableField, Nullable} from '@px/shared/types';
import {AppState} from '../../../shared/store/app/app.state';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import last from 'lodash/last';
import {IPSSTemplate} from '../../../shared/models/pss-template';
import {IPSSAudio} from '../../../shared/models/pss-audio.model';
import {Photo} from '@px/legacy/feature-photo-uploader';
import {SlideshowDataAdapterService} from '@px/shared/slideshow-adapter';
import {ISegment} from '@px/shared/api';
import getSavedPreferencesState = PreferencesSelectors.getSavedPreferencesState;

export const getTPPData = (segment: Nullable<Segment>, templates: IPSSTemplate[]): TPPData | void => {
  const platform = PSSPlatformEnvironment.getSelf();

  if (!templates.length || !segment?.photosIds.length) {
    return;
  }

  const primaryBtn: TPPPrimaryBtn = {templateId: null, type: null};

  templates = templates
    //TODO: we filter zero calculations because it happens when trimmed music doesn't include any medians
    .filter(template => template.matching_images_calculated)
    .map((template: IPSSTemplate, i): IPSSTemplate => {
      if (segment.photosIds.length < template.matching_images_calculated) {
        template.trimPoint = countTrimPoint(template, segment);
        if (primaryBtn.templateId === null) {
          if (i === 0) {
            primaryBtn.templateId = template.id;
            primaryBtn.type =
              (segment.audio_end - template.trimPoint) / (segment.audio_end - segment.audio_start) <= 0.5 &&
              template.trimPoint - segment.audio_start >= platform.COMMON_SETTINGS.SLIDESHOW_LIMITS.MIN_SEGMENT_DURATION
                ? TPPPrimaryBtnType.TRIM
                : TPPPrimaryBtnType.ADD_IMAGES;
          } else {
            if (
              segment.photosIds.length <=
              Math.round((template.matching_images_calculated + templates[i - 1].matching_images_calculated) / 2)
            ) {
              primaryBtn.templateId = templates[i - 1].id;
              primaryBtn.type =
                segment.photosIds.length - templates[i - 1].matching_images_calculated > 30
                  ? TPPPrimaryBtnType.MOVING
                  : TPPPrimaryBtnType.DELETE;
            } else {
              primaryBtn.templateId = template.id;
              primaryBtn.type =
                (segment.audio_end - template.trimPoint) / (segment.audio_end - segment.audio_start) <= 0.5 &&
                template.matching_images_calculated - segment.photosIds.length > 5
                  ? TPPPrimaryBtnType.TRIM
                  : TPPPrimaryBtnType.ADD_IMAGES;
            }
          }
        }
      }

      return template;
    });

  if (primaryBtn.templateId === null) {
    const template = last(templates);

    primaryBtn.templateId = template?.id;

    primaryBtn.type =
      segment.photosIds.length - template?.matching_images_calculated > 30
        ? TPPPrimaryBtnType.MOVING
        : TPPPrimaryBtnType.DELETE;
  }

  return {
    templates,
    primaryBtn,
  };
};

/**
 * @deprecated use selectors by ID
 */
export namespace SlideshowSelectors {
  export const getActualSlideshowState = createSelector(AppSelectors.getSlideshowState, state => state.actualState);

  const {selectEntities: selectPhotoEntities} = photoAdapter.getSelectors();

  export const getPhotoList = createSelector(getActualSlideshowState, state => state.photoList);

  export const getPhotoEntities = createSelector(getPhotoList, selectPhotoEntities);
  export const getPhotoById = (id: string | number): ((state: AppState) => Nullable<Photo>) =>
    createSelector(getPhotoEntities, entities => {
      return entities[id] ?? Object.values(entities).find(p => p.locals?.uuid === id);
    });
  export const getFailedUploadEntities = createSelector(getPhotoEntities, entities =>
    Object.values(entities).filter(p => !!p.locals?.errorType && !p.locals?.mirror_uuid && !p.locals?.mirror_id)
  );

  const {selectEntities: selectSegmentEntities, selectAll: selectAllSegments} = segmentAdapter.getSelectors();

  export const getSegmentList = createSelector(getActualSlideshowState, state => state.segmentList) as MemoizedSelector<
    AppState,
    SegmentState
  >;

  export const getAllSegmentsRaw = createSelector(getSegmentList, selectAllSegments);

  export const getAllSegments = createSelector(getAllSegmentsRaw, getPhotoEntities, (segments, photos): Segment[] => {
    return segments.map(segment => ({
      ...segment,
      locals: {
        ...segment.locals,
        isLowResolution: segment.photosIds.some(id => photos[id]?.is_low_resolution),
        uploadWarnings: {
          isLowResolution: segment.photosIds.some(id => photos[id]?.is_low_resolution),
          isColorProfileConverted: segment.photosIds.some(id => photos[id]?.is_color_profile_updated),
          isColorProfileMissed: segment.photosIds.some(id => photos[id]?.is_color_profile_detected === false),
          get hasOnlyLowResolutionWarning() {
            return this.isLowResolution && !(this.isColorProfileConverted || this.isColorProfileMissed);
          },
          get hasOnlyColorProfileWarning() {
            return !this.isLowResolution && (this.isColorProfileConverted || this.isColorProfileMissed);
          },
          get hasAllWarnings() {
            return this.isLowResolution && (this.isColorProfileConverted || this.isColorProfileMissed);
          },
        },
        uploadErrorCount: segment.photosIds.filter(id => photos[id]?.locals && photos[id]?.locals.errorType).length,
        uploadImagesCount: segment.photosIds.filter(
          id => photos[id]?.locals && 'uploaderItem' in photos[id].locals && !photos[id].locals.errorType
        ).length,
      },
    }));
  });

  export const getSegmentEntities = createSelector(getSegmentList, selectSegmentEntities);

  export const getSlideshowLocalsFromSegments = createSelector(
    getActualSlideshowState,
    getAllSegments,
    (slideshowState, segments) => {
      const slideshowSongsExpireTime = SlideshowDataAdapterService.getSlideshowSongsExpireTime(
        segments as unknown as ISegment[],
        slideshowState.common_data?.state ?? null
      );
      return {
        songsExpireTime: slideshowSongsExpireTime,
        hasExpiredSongs: slideshowSongsExpireTime > 0 ? slideshowSongsExpireTime <= new Date().getTime() : false,
        notCorrectDataValue: checkSlideshowError(segments),
      };
    }
  );

  export const getSlideshowLocals = createSelector(
    getActualSlideshowState,
    getSlideshowLocalsFromSegments,
    (state, slideshowLocalsFromSegments) => ({
      ...state.locals,
      ...slideshowLocalsFromSegments,
    })
  );

  export const getSlideshowOptions = createSelector(getActualSlideshowState, slideshow => {
    const {locals, segmentList, photoList, urls, ...slideshowOptions} = slideshow;
    return slideshowOptions;
  });

  export const selectSelectedSegmentId = createSelector(
    getSegmentList,
    state => state.selectedSegmentId
  ) as MemoizedSelector<AppState, string | number>;

  export const getSelectedSegment = createSelector(
    getSegmentEntities,
    selectSelectedSegmentId,
    getPhotoEntities,
    (segmentEntities, segmentId, photos) => {
      return segmentEntities[segmentId]
        ? {
            ...segmentEntities[segmentId],
            uploadError: segmentEntities[segmentId].photosIds.some(id => photos[id]?.locals?.errorType),
          }
        : segmentEntities[segmentId];
    }
  ) as MemoizedSelector<AppState, Segment>;

  export const getSegmentByPhotoId = (id: Photo['id']): ((state: AppState) => Nullable<Segment>) =>
    createSelector(getSegmentEntities, segments =>
      Object.values(segments).find(segment => segment.photosIds.includes(id))
    );

  export const getSlideshowLength = createSelector(getAllSegments, segments => {
    return segments.reduce((acc, segment) => {
      return acc + segment.audio_end - segment.audio_start;
    }, 0);
  });

  export const getSlideshowUrls = createSelector(getSavedPreferencesState, (preferences, props) => ({
    previewUrl: getSlideshowPreviewUrl(props.slug, preferences),
    embedUrl: getSlideshowPreviewUrl(props.slug, preferences, true),
  }));

  export const getSlideshowImagesCount = createSelector(getAllSegmentsRaw, state =>
    state.reduce((acc, cur) => acc + cur.photosIds.length, 0)
  );

  export const getStatusSlideshowState = createSelector(AppSelectors.getSlideshowState, state => state.statusState);

  export const getLoaded = createSelector(getStatusSlideshowState, statusState => statusState.loaded);

  export const getSaving = createSelector(getStatusSlideshowState, statusState => statusState.saving);

  export const getAutoSaving = createSelector(getStatusSlideshowState, statusState => statusState.autoSaving);

  export const getTrimming = createSelector(getStatusSlideshowState, statusState => statusState.isTrimming);

  export const getDragging = createSelector(getStatusSlideshowState, statusState => statusState.isDragging);

  export const getPublishing = createSelector(getStatusSlideshowState, statusState => statusState.publishing);

  export const getReverting = createSelector(getStatusSlideshowState, statusState => statusState.reverting);

  export const getView = createSelector(getStatusSlideshowState, statusState => statusState.view);

  export const getSavedSlideshowState = createSelector(AppSelectors.getSlideshowState, state => state.savedState);

  export const getSavedState = createSelector(getSavedSlideshowState, state => state.common_data.state);

  export const selectedPhotos = createSelector(getPhotoList, (state: PhotoState) =>
    Object.values(state.entities).filter(item => item.selected)
  );
}

export const getSegmentById = (id: ExtractField<Segment, 'id'>): ((state: AppState) => Nullable<Segment>) =>
  createSelector(
    SlideshowSelectors.getSegmentEntities,
    SlideshowSelectors.getPhotoEntities,
    (segmentsDict: Dictionary<Segment>, photosDict: Dictionary<Photo>): Nullable<Segment> => {
      return segmentsDict[id]
        ? {
            ...segmentsDict[id],
            uploadError: segmentsDict[id].photosIds.some(id => photosDict[id]?.locals?.errorType),
          }
        : null;
    }
  ) as MemoizedSelector<AppState, Segment>;

export const getAudioBySegmentId = (id: ExtractField<Segment, 'id'>): ((state: AppState) => Nullable<IPSSAudio>) =>
  createSelector(getSegmentById(id), (segment: Nullable<Segment>): Nullable<IPSSAudio> => segment?.audio ?? null);

export const getActiveTemplatesForAudioBySegmentId = (
  id: ExtractField<Segment, 'id'>
): ((state: AppState) => IPSSTemplate[]) =>
  createSelector(getAudioBySegmentId(id), (audio: Nullable<IPSSAudio>): IPSSTemplate[] => {
    return audio?.activeTemplates ?? [];
  });

export const getAudioStartFromSegmentById = (
  id: ExtractField<Segment, 'id'>
): ((state: AppState) => ExtractNullableField<Segment, 'audio_start'>) =>
  createSelector(
    getSegmentById(id),
    (segment: Nullable<Segment>): ExtractNullableField<Segment, 'audio_start'> => segment?.audio_start
  );

export const getAudioEndFromSegmentById = (
  id: ExtractField<Segment, 'id'>
): ((state: AppState) => ExtractNullableField<Segment, 'audio_end'>) =>
  createSelector(
    getSegmentById(id),
    (segment: Nullable<Segment>): ExtractNullableField<Segment, 'audio_end'> => segment?.audio_end
  );

export const getAudioLengthFromSegmentById = (
  id: ExtractField<Segment, 'id'>
): ((state: AppState) => ExtractNullableField<IPSSAudio, 'length'>) =>
  createSelector(
    getAudioBySegmentId(id),
    (audio: Nullable<IPSSAudio>): ExtractNullableField<IPSSAudio, 'length'> => audio?.length
  );

export const getActiveTemplatesBySegmentId = (id: ExtractField<Segment, 'id'>): ((state: AppState) => IPSSTemplate[]) =>
  createSelector(
    getAudioStartFromSegmentById(id),
    getAudioEndFromSegmentById(id),
    getAudioLengthFromSegmentById(id),
    getActiveTemplatesForAudioBySegmentId(id),
    (
      start: ExtractNullableField<Segment, 'audio_start'>,
      end: ExtractNullableField<Segment, 'audio_end'>,
      length: ExtractNullableField<IPSSAudio, 'length'>,
      templates: IPSSTemplate[]
    ) => getActiveTemplatesForSegment(start, end, length, templates)
  );

export const getSegmentWithAudioById = (id: ExtractField<Segment, 'id'>): ((state: AppState) => Nullable<Segment>) =>
  createSelector(
    getSegmentById(id),
    getActiveTemplatesBySegmentId(id),
    (segment: Segment | null, templates: IPSSTemplate[]) => {
      const template = templates.find(template => template.matching_images_calculated === segment?.photosIds.length);

      return segment
        ? ({
            ...segment,
            bmPossibleId: template?.id,
          } as Segment)
        : null;
    }
  );

export const getTPPDataBySegmentId = (id: ExtractField<Segment, 'id'>): ((state: AppState) => TPPData | void) =>
  createSelector(getSegmentWithAudioById(id), getActiveTemplatesBySegmentId(id), getTPPData);

export const getPhotosBySegmentId = (id: ExtractField<Segment, 'id'>): ((state: AppState) => Photo[]) => {
  return createSelector(
    SlideshowSelectors.getPhotoEntities,
    getSegmentById(id),
    (photoDict: Dictionary<Photo>, segment: Nullable<Segment>): Photo[] => {
      if (!segment?.photosIds) {
        return [];
      }

      return segment.photosIds.reduce((acc: Photo[], id: string | number) => {
        const {[id]: item} = photoDict;

        if (item) {
          acc.push({
            ...item,
            selected: segment.selectedPhotos?.[id]?.selected,
          });
        }

        return acc;
      }, []);
    }
  );
};
