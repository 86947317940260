import {inject, Inject, Injectable, Injector} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {EMPTY, firstValueFrom, from, Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import {LogInSuccess} from './auth.actions';
import {User} from '../../shared/models/user.model';
import {TranslateManagerService} from '@px/pss/feature-translate';
import {AbTestsService} from '../../shared/angular-ab-tests/service';
import {BeaconService} from '../services/beacon.service';
import {GoogleTagManagerService} from '../services/google-tag-manager.service';
import {DelightedService} from '../services/delighted.service';
import {HttpErrorResponse} from '@angular/common/http';
import {PssTrackingService} from '@px/pss/feature-tracking';
import {Preferences} from '../../shared/models/preferences.model';
import {AUTH_SERVICE, IPreferencesService, IUser, PREFERENCES_SERVICE} from '@px/shared/api';
import {PhotographerAuthFacade} from '@px/photographer-auth/domain';
import {User as UserConfigCat} from 'configcat-common';
import {FEATURE_FLAGS_CONFIGURATOR, IFeatureFlagsConfigurator, Languages} from '@px/shared/env';
import {PSSPlatformFeatures} from '@px/pss/platform-env';
import {DataDogService} from '@px/shared/data-access/data-dog';
import {SessionProviderFacade, SessionSource} from '@px/shared/session-provider';
import {IPSSAuthService} from '../models/auth-service.interface';

@Injectable()
export class AuthEffects {
  private readonly dataDogService = inject(DataDogService);
  private readonly sessionProvider = inject(SessionProviderFacade);
  readonly injector = inject(Injector);

  private get preferencesService(): IPreferencesService {
    return this.injector.get(PREFERENCES_SERVICE);
  }

  private get authService(): IPSSAuthService {
    return this.injector.get<IPSSAuthService>(AUTH_SERVICE);
  }

  private static createConfigCatUser(user: IUser): UserConfigCat {
    return {
      identifier: user.user_px_id.toString(),
      email: user.email,
      custom: {
        isTrial: user.is_trial.toString(),
        createdAt: user.created_at.toString(),
        subscriptionType: user.user_plan_id.toString(),
      },
    };
  }

  logInCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LogInCheck),
      exhaustMap(() => {
        const sessionService = this.sessionProvider.getSessionService();

        if (this.photographerAuthFacade.hasSession()) {
          let observable$: Observable<void> = of(undefined);

          if (this.photographerAuthFacade.isSessionExpired()) {
            observable$ = this.photographerAuthFacade.refresh();
          }
          return observable$.pipe(
            switchMap(() =>
              this.authService.jwtAuthorize({
                jwt: sessionService.session?.token as string,
                refresh_id: sessionService.session?.refreshId as string,
                expiration: sessionService.session?.expiration as number,
              })
            ),
            switchMap(() => this.authService.logInCheck()),
            tap(response => {
              try {
                this.dataDogService.setUser({
                  id: response.data.user_id.toString(),
                });
              } catch (err) {
                console.error(err);
              }
            }),
            switchMap(async response => {
              try {
                await firstValueFrom(
                  this.featureFlagsConfigurator.configureFeaturesAndConfigs(
                    AuthEffects.createConfigCatUser(response.data)
                  )
                );
              } catch (e) {
                console.error(e);
              }
              return response;
            }),
            map(response => AuthActions.LogInSuccess({user: response.data})),
            catchError(err => {
              console.error(err);

              sessionService.logOut();
              return of(AuthActions.LogInFailure({error: ''}));
            })
          );
        }

        return of(AuthActions.LogInFailure({error: ''}));
      })
    )
  );

  logInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogInSuccess),

        tap(action => {
          if (action.redirect) {
            this.router.navigate(['/']);
          }
        }),
        tap(action => {
          if (action.user.trial_user_first_login) {
            this.googleTabManagerService.pushToDataLayer({event: 'trialUserFirstLogin'});
          } else if (action.user.customer_first_login) {
            this.googleTabManagerService.pushToDataLayer({event: 'customerFirstLogin'});
          }
        }),
        switchMap((action: ReturnType<typeof LogInSuccess>) =>
          from(this.pssTrackingService.connect(action.user, {})).pipe(map(() => action))
        ),
        switchMap((action: ReturnType<typeof LogInSuccess>) => {
          if (this.photographerAuthFacade.sessionSource === SessionSource.QUERY_PARAMS) {
            this.pssTrackingService.login();
          }

          this.beaconService.init(action.user);
          this.delightedService.init(action.user);

          // ToDo
          this.abTestsService.setVersion('new');
          return this.translateManagerService.changeLang(action.user.language);
        })
      ),
    {dispatch: false}
  );

  logInFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogInFailure),
        exhaustMap(() => this.translateManagerService.changeLang(Languages.auto))
      ),
    {dispatch: false}
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogInRedirect),
        tap(() => {
          const sessionService = this.sessionProvider.getSessionService();

          sessionService.handleNoSession().subscribe();
        })
      ),
    {dispatch: false}
  );

  mainPageRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.MainPageRedirect),
        tap(() => this.router.navigate(['/']))
      ),
    {dispatch: false}
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogOut),
        switchMap(() =>
          this.authService.logOut().pipe(
            catchError(error => {
              console.error(error);
              return of(null);
            })
          )
        ),
        tap(() => {
          const sessionService = this.sessionProvider.getSessionService();

          sessionService.logOut();
          this.authService.dropAuthCache();
          this.pssTrackingService.destroy();
          this.beaconService.destroy();
          this.dataDogService.clearUser();
          this.photographerAuthFacade.redirectToLogOut();
        })
      ),
    {dispatch: false}
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.UpdateUser),
      map(action => action.user),
      switchMap((user: User) => {
        // TODO Have to fix type
        return this.preferencesService.save(user as Preferences).pipe(
          map(() => AuthActions.UpdateUserSuccess(user)),
          catchError((response: HttpErrorResponse) => {
            console.error(response);
            return EMPTY;
          })
        );
      })
    )
  );

  saveLastChosenTransition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SaveLastChosenTransition),
      map(action => action.user),
      exhaustMap(user => {
        return this.authService
          .saveLastTransition({
            transition_id: user.last_transition_id,
            transition_duration: user.transition_duration,
          })
          .pipe(
            map(() => AuthActions.UpdateUserSuccess(user)),
            catchError((response: HttpErrorResponse) => {
              console.error(response);
              return EMPTY;
            })
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly translateManagerService: TranslateManagerService,
    private readonly beaconService: BeaconService,
    private readonly delightedService: DelightedService,
    private readonly router: Router,
    private readonly abTestsService: AbTestsService,
    private readonly googleTabManagerService: GoogleTagManagerService,
    private readonly pssTrackingService: PssTrackingService,
    private readonly photographerAuthFacade: PhotographerAuthFacade,
    @Inject(FEATURE_FLAGS_CONFIGURATOR)
    private readonly featureFlagsConfigurator: IFeatureFlagsConfigurator<PSSPlatformFeatures, UserConfigCat>
  ) {}
}
