import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnsupportedBrowserComponent} from './unsupported-browser.component';
import {TranslateModule} from '@ngx-translate/core';
import {MdsIconComponent, mdsProvideIcons, pxChrome, pxEdge, pxFirefox, pxSafari} from '@pui/components/icon-core';

@NgModule({
  imports: [CommonModule, TranslateModule, MdsIconComponent, MdsIconComponent],
  declarations: [UnsupportedBrowserComponent],
  exports: [UnsupportedBrowserComponent],
  providers: [mdsProvideIcons({pxChrome, pxEdge, pxFirefox, pxSafari})],
})
export class FeatureUnsupportedBrowserModule {}
