import {ChangeDetectionStrategy, Component, Input, numberAttribute} from '@angular/core';
import {MdsIconComponent, mdsProvideIcons, pxSpinner} from '@pui/components/icon-core';

@Component({
  selector: 'mds-spinner',
  imports: [MdsIconComponent],
  providers: [mdsProvideIcons({pxSpinner})],
  template: '<mds-icon class="spinner" icon="pxSpinner" />',
  styleUrls: ['./spinner.component.scss'],
  host: {
    '[style.--mds-comp-spinner-size.px]': 'diameter',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsSpinnerComponent {
  @Input({transform: numberAttribute}) diameter = 16;
}
