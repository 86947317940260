import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MdsAppLoaderService} from '@mds/components/app-loader';
import {UnsupportedBrowserComponent as LegacyUnsupportedBrowserComponent} from '@px/legacy/feature-unsupported-browser';

@Component({
  selector: 'pss-unsupported-browser',
  imports: [LegacyUnsupportedBrowserComponent],
  providers: [MdsAppLoaderService],
  templateUrl: './unsupported-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowserComponent implements OnInit {
  private readonly mdsAppLoaderService = inject(MdsAppLoaderService);

  ngOnInit(): void {
    this.mdsAppLoaderService.completeLoading();
  }
}
