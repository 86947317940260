<ng-template>
  <div
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    class="c-dropdown"
    [style.transform-origin]="transformOrigin"
    [@animationTransition]="animationState"
    [ngClass]="{
      'c-dropdown--default': type === MDS_DROPDOWN_TYPE.default,
      'c-dropdown--base': type === MDS_DROPDOWN_TYPE.base,
      'c-dropdown--menu': type === MDS_DROPDOWN_TYPE.menu,
      'c-dropdown--select': type === MDS_DROPDOWN_TYPE.select,
      'c-dropdown--clean-small': type === MDS_DROPDOWN_TYPE.cleanSmall,
      'c-dropdown--hint': type === MDS_DROPDOWN_TYPE.hint
    }"
  >
    <div class="c-dropdown__body">
      <ng-content />
    </div>
  </div>
</ng-template>
