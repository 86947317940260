import {inject, provideAppInitializer} from '@angular/core';
import {FirstPromoterService} from './services/first-promoter.service';
import {PlatformEnvironment} from '@px/shared/env';

export const FIRST_PROMOTER_INITIALIZER = provideAppInitializer(() => {
  const initializerFn = (() => {
    const platformEnv = inject(PlatformEnvironment);
    const firstPromoterService = inject(FirstPromoterService);

    return (): Promise<void> =>
      platformEnv.FIRST_PROMOTER_ENABLED ? firstPromoterService.init(platformEnv.FIRST_PROMOTER) : Promise.resolve();
  })();
  return initializerFn();
});
