import {IEventBusService} from './event-bus-service.interface';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {IBusEvent} from './bus-event';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventBusService implements IEventBusService, OnDestroy {
  private readonly eventBus = new ReplaySubject<IBusEvent>(1);

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data as T)
    );
  }

  emit<T>(event: IBusEvent<T>): void {
    this.eventBus.next(event);
  }

  ngOnDestroy(): void {
    this.eventBus.complete();
  }
}
