import {ConnectedPosition, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  booleanAttribute,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, fromEvent, merge} from 'rxjs';
import {MDS_DROPDOWN_POSITION, MDS_DROPDOWN_POSITION_OFFSET} from './dropdown.costants';
import {IMdsDropdownPanel} from './dropdown.interfaces';

@UntilDestroy()
@Directive({
  selector: '[mdsDropdownTriggerFor]',
  standalone: true,
  exportAs: 'mdsDropdownTriggerFor',
})
export class MdsDropdownTriggerForDirective<T = ElementRef> implements OnDestroy {
  private readonly overlay = inject(Overlay);
  private readonly elementRef = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);

  private overlayRef?: OverlayRef;

  isOpened = false;
  showDropdownTimeout?: number;

  @Output() close$ = new EventEmitter<void>();
  @Output() open$ = new EventEmitter<void>();

  @Input('mdsDropdownTriggerFor') dropdownPanel?: IMdsDropdownPanel<T>;
  @Input() mdsDropdownDisabled?: boolean;
  @Input() positionOffset?: number;
  @Input() hasBackdrop?: boolean = true;
  @Input() position?: 'center' | 'flexible' = 'flexible';
  @Input({transform: booleanAttribute}) mdsDropdownTriggerHover = false;
  @Input('mdsDropdownTriggerForContext') context?: T;

  @HostListener('click')
  @HostListener('keydown.enter')
  toggle(): void {
    if (this.mdsDropdownTriggerHover) return;
    if (this.isOpened) {
      this.destroy();
      return;
    }
    this.open();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.onMouseEnterDropdown();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.omnMouseLeaveDropdown();
  }

  onMouseEnterDropdown(): void {
    if (!this.mdsDropdownTriggerHover) return;

    if (this.showDropdownTimeout) {
      window.clearTimeout(this.showDropdownTimeout);
    }

    if (this.isOpened) return;

    this.open();
  }

  omnMouseLeaveDropdown(): void {
    if (!this.mdsDropdownTriggerHover) return;

    if (this.showDropdownTimeout) {
      window.clearTimeout(this.showDropdownTimeout);
    }

    this.showDropdownTimeout = window.setTimeout(() => {
      this.destroy();
    }, 300);
  }

  open(): void {
    if (this.mdsDropdownDisabled || this.elementRef.nativeElement.getAttribute('disabled') !== null) {
      return;
    }

    const positionStrategy =
      this.position === 'center'
        ? this.overlay.position().global().centerHorizontally().centerVertically()
        : this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withLockedPosition()
            .withPositions(this.getPositions());

    this.isOpened = true;
    this.open$.emit();

    const hasBackdrop = this.mdsDropdownTriggerHover ? false : this.hasBackdrop;

    this.overlayRef = this.overlay.create({
      hasBackdrop: hasBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    });

    if (this.dropdownPanel?.templateRef) {
      this.dropdownPanel.context = this.context;
      this.dropdownPanel.transformOrigin = this.getTransformOrigin();
      const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this.viewContainerRef);
      this.overlayRef.attach(templatePortal);
      this.overlayRef.updatePosition();
      this.initTriggerHover(this.overlayRef.overlayElement);
    }

    merge(
      this.overlayRef?.backdropClick(),
      this.overlayRef?.detachments(),
      this.overlayRef?.keydownEvents().pipe(filter(event => event.key === 'Escape'))
    )
      .pipe(untilDestroyed(this, 'destroy'))
      .subscribe(() => this.destroy());

    this.dropdownPanel?.close$.pipe(untilDestroyed(this, 'destroy')).subscribe(() => {
      this.destroy();
    });
  }

  initTriggerHover(el: HTMLElement): void {
    if (!this.mdsDropdownTriggerHover) return;

    const mouseEnter$ = fromEvent(el, 'mouseenter');
    const mouseLeave$ = fromEvent(el, 'mouseleave');

    mouseEnter$.pipe(untilDestroyed(this)).subscribe(() => {
      this.onMouseEnterDropdown();
    });

    mouseLeave$.pipe(untilDestroyed(this)).subscribe(() => {
      this.omnMouseLeaveDropdown();
    });
  }

  getTransformOrigin(): string {
    let transformOrigin: string;

    switch (this.dropdownPanel?.position) {
      case MDS_DROPDOWN_POSITION.topLeft:
        transformOrigin = 'bottom left';
        break;
      case MDS_DROPDOWN_POSITION.topRight:
        transformOrigin = 'bottom right';
        break;
      case MDS_DROPDOWN_POSITION.bottomLeft:
        transformOrigin = 'top left';
        break;
      case MDS_DROPDOWN_POSITION.bottomRight:
        transformOrigin = 'top right';
        break;
      case MDS_DROPDOWN_POSITION.bottomCenter:
        transformOrigin = 'top center';
        break;
      case MDS_DROPDOWN_POSITION.topCenter:
        transformOrigin = 'bottom center';
        break;
      default:
        transformOrigin = 'center';
        break;
    }

    return transformOrigin;
  }

  getPositions(): ConnectedPosition[] {
    const positions: ConnectedPosition[] = [];
    const positionOffset = this.positionOffset ?? MDS_DROPDOWN_POSITION_OFFSET;
    const definedPositions: {[key: string]: ConnectedPosition} = {
      [MDS_DROPDOWN_POSITION.leftCenter]: {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        offsetX: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.rightCenter]: {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.topLeft]: {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.topRight]: {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetY: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.bottomLeft]: {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.bottomRight]: {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.bottomCenter]: {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: positionOffset,
      },
      [MDS_DROPDOWN_POSITION.topCenter]: {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: positionOffset,
      },
    };

    switch (this.dropdownPanel?.position) {
      case MDS_DROPDOWN_POSITION.topLeft:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.topLeft]);
        break;
      case MDS_DROPDOWN_POSITION.topRight:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.topRight]);
        break;
      case MDS_DROPDOWN_POSITION.bottomLeft:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.bottomLeft]);
        break;
      case MDS_DROPDOWN_POSITION.bottomRight:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.bottomRight]);
        break;
      case MDS_DROPDOWN_POSITION.bottomCenter:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.bottomCenter]);
        break;
      case MDS_DROPDOWN_POSITION.topCenter:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.topCenter]);
        break;
      case MDS_DROPDOWN_POSITION.leftCenter:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.leftCenter]);
        break;
      case MDS_DROPDOWN_POSITION.rightCenter:
        positions.push(definedPositions[MDS_DROPDOWN_POSITION.rightCenter]);
        break;
      default:
        positions.push(
          definedPositions[MDS_DROPDOWN_POSITION.topLeft],
          definedPositions[MDS_DROPDOWN_POSITION.topRight],
          definedPositions[MDS_DROPDOWN_POSITION.bottomLeft],
          definedPositions[MDS_DROPDOWN_POSITION.bottomRight]
        );
        break;
    }

    return positions;
  }

  destroy(): void {
    if (!this.overlayRef || !this.isOpened) {
      return;
    }

    this.isOpened = false;
    this.overlayRef.dispose();

    this.close$.emit();
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }
}
