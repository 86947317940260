import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {plainToClass} from 'class-transformer';
import {PSSPlatformEnvironment} from './app/platform-environment';
import {IPSSPlatformConfig} from './app/core/interfaces/platform-config';
import {IApiHealthCheck, IApiResponse} from '@px/shared/api';
import {PlatformEnvironment} from '@px/shared/env';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';

if (environment.production) {
  enableProdMode();
}

(async (): Promise<void> => {
  let platformEnvironment: PSSPlatformEnvironment;

  try {
    const env: IPSSPlatformConfig = await fetch(
      `/assets/configs/environment.json?${PSSPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const overrides: Record<string, IPSSPlatformConfig> = await fetch(
      `/assets/configs/overrides.json?${PSSPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const platformConfig = Object.assign(env, overrides ?? {});

    if (PSSPlatformEnvironment.IS_DEV) {
      platformEnvironment = plainToClass(PSSPlatformEnvironment, platformConfig);
    } else {
      try {
        const featureFlags: IApiResponse<Partial<IPSSPlatformConfig>> = await fetch(
          new URL('/feature-flags/', platformConfig.API_URL).toString(),
          {headers: {'x-px-scope': platformConfig.SCOPE}}
        ).then(res => res.json());

        platformEnvironment = plainToClass(
          PSSPlatformEnvironment,
          Object.assign(platformConfig, {
            FEATURES: Object.assign(platformConfig.FEATURES, featureFlags.data.FEATURES),
            FEATURE_CONFIG: Object.assign(platformConfig.FEATURE_CONFIG, featureFlags.data.FEATURE_CONFIG ?? {}),
            production: environment.production,
          })
        );
      } catch (e) {
        console.warn(e);

        platformEnvironment = plainToClass(PSSPlatformEnvironment, platformConfig);
      }
    }

    if (!environment.production) {
      const healthCheck: IApiHealthCheck = await fetch(
        new URL('/health-check/', platformConfig.API_URL).toString()
      ).then(res => res.json());

      platformEnvironment.setApiHealthCheck(healthCheck);
    }
  } catch (e) {
    console.warn('USES FALLBACK CONFIG');
    console.warn(e);
    platformEnvironment = plainToClass(PSSPlatformEnvironment, {});
  }

  platformBrowserDynamic([
    {provide: PlatformEnvironment, useValue: platformEnvironment},
    {provide: PSSPlatformEnvironment, useValue: platformEnvironment},
    {provide: MATERIAL_SANITY_CHECKS, useValue: false},
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.warn(err));
})();
