import {inject, Injectable} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import {ApiObject} from '@rudderstack/analytics-js';
import {RudderstackLegacyService} from './rudderstack-legacy.service';
import {RudderstackFreshService} from './rudderstack-fresh.service';
import {apiObject} from 'rudder-sdk-js';

@Injectable({providedIn: 'root'})
export class RudderstackService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly rudderstackFreshService = inject(RudderstackFreshService);
  private readonly rudderstackLegacyService = inject(RudderstackLegacyService);

  private readonly updateFeatureflag = 'RUDDERSTACK_UPDATE';

  isEnabled$ = this.platform.hasFeature(this.updateFeatureflag)
    ? this.rudderstackFreshService.isEnabled$
    : this.rudderstackLegacyService.isEnabled$;

  async init(): Promise<void> {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.init();
    }
    return this.rudderstackLegacyService.init();
  }

  track(id: string, action: ApiObject = {}): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.track(id, action);
    }
    return this.rudderstackLegacyService.track(id, action as apiObject);
  }

  setSuperProperties(properties: ApiObject): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.setSuperProperties(properties);
    }
    return this.rudderstackLegacyService.setSuperProperties(properties as apiObject);
  }

  setUserProperties(properties: ApiObject): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.setUserProperties(properties);
    }
    return this.rudderstackLegacyService.setUserProperties(properties as apiObject);
  }

  incrementUserProperties(property: string, incrementBy = 1): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.incrementUserProperties(property, incrementBy);
    }
    return this.rudderstackLegacyService.incrementUserProperties(property, incrementBy);
  }

  identify(userId?: string | number): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.identify(userId);
    }
    return this.rudderstackLegacyService.identify(userId);
  }

  destroy(): void {
    if (this.platform.hasFeature(this.updateFeatureflag)) {
      return this.rudderstackFreshService.destroy();
    }
    return this.rudderstackLegacyService.destroy();
  }
}
