<div
  class="c-snackbar"
  [style.transformOrigin]="transformOrigin"
  [@dialogContainer]="animationState"
  (@dialogContainer.start)="onAnimationStart($event)"
  (@dialogContainer.done)="onAnimationDone($event)"
>
  <ng-template cdkPortalOutlet></ng-template>
  <ng-content />
</div>
