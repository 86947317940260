import {RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromSlideshowOptions from '../../../main/store/slideshow-options/slideshow-options.reducer';
import {GlobalState} from '../global/global.state';
import {IManagerState} from '../../../main/manager/store/IManagerState';
import {PreferencesState} from '../../../main/preferences/store/preferences.state';
import {SlideshowStates} from '../../../main/store/slideshow/slideshow.state';
import {AuthState} from '../../../auth/store/auth.state';
import {IVideoRenderingState} from '../../../main/store/video-rendering/video-rendering.state';
import {ShareDialogState} from '../../../main/shared/components/share-dialog/store/share-dialog.reducer';

export namespace AppSelectors {
  export const getGlobalState = createFeatureSelector<GlobalState>('globals');
  export const getAuthState = createFeatureSelector<AuthState>('auth');
  export const getManagerState = createFeatureSelector<IManagerState>('manager');
  export const getPreferencesState = createFeatureSelector<PreferencesState>('preferences');
  export const getRouterReducerState = createFeatureSelector<RouterReducerState>('router');
  export const getSlideshowOptionsState = createFeatureSelector<fromSlideshowOptions.State>('slideshowOptions');
  export const getSlideshowState = createFeatureSelector<SlideshowStates>('slideshow');
  export const getVideoRenderingState = createFeatureSelector<IVideoRenderingState>('videoRendering');
  export const getShareDialogState = createFeatureSelector<ShareDialogState>('shareDialog');
  export const getRouterState = createSelector(getRouterReducerState, router => (router ? router.state : null));

  export const selectRouteFragment = createSelector(getRouterState, state => (state ? state.root.fragment : ''));

  export const selectSlideshowOptionsData = createSelector(
    getSlideshowOptionsState,
    fromSlideshowOptions.getSlideshowData
  );

  export const selectDialogRef = createSelector(getSlideshowOptionsState, fromSlideshowOptions.getDialogRef);

  export const selectSlideshowOptionsSaving = createSelector(
    getSlideshowOptionsState,
    fromSlideshowOptions.getSlideshowSaving
  );
}
