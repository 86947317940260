<div class="pricing">
  @if (showPeriodSelector) {
    <div class="pricing__period-selector">
      <px-pricing-period-selector
        [disabled]="isLoading || !!buyingProduct"
        [value]="period"
        (valueChange)="period$.next($event)"
      />
      @if (showPricingYearly) {
        <span class="pricing__promo">{{ 'Save 33% with yearly billing' | translate }}</span>
      }
    </div>
  }

  @if (!isLoading) {
    <div class="pricing__plans">
      @for (billingProduct of billingProductsByPeriod$ | async; track billingProduct.id) {
        <px-pricing-plan-card
          [class.highlighted]="isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY"
          [title]="billingProduct.name"
          [priceMonthly]="billingProduct.priceMonthly"
          [priceYearly]="billingProduct.priceYearly"
          [newPrice]="isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY"
          [newPriceMonthly]="getSale10Price(billingProduct.priceMonthly)"
          [newPriceYearly]="getSale10Price(billingProduct.priceYearly)"
          [features]="billingProduct.features"
          [promoTitle]="
            isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY
              ? ('Save 30% for 1 year' | translate)
              : (billingProduct.promo ?? '' | translate)
          "
          [highlighted]="!!billingProduct.promo"
          [currencyCode]="billingProduct.currencyCode"
          [actionBlocked]="!!buyingProduct"
          [actionDisabled]="disableUnavailablePlans && !!activeBundleSubscription?.id"
          [actionPerformed]="buyingProduct?.id === billingProduct.id"
          [subscribed]="isProductSubscribed(billingProduct.id)"
          [actionLabel]="getActionLabel(billingProduct.productId) | translate | titlecase"
          (buy$)="buy(billingProduct)"
        />
      }
    </div>
  } @else {
    <mds-skeleton isAnimated>
      <div class="plans-skeleton">
        @for (skeleton of skeletons; track $index) {
          <div class="plans-skeleton__item">
            <div class="plans-skeleton__card">
              <mds-skeleton-bone class="plans-skeleton__bone plans-skeleton__title" />
              <div class="plans-skeleton__price">
                <mds-skeleton-bone width="70px" height="32px" />
              </div>
              <div class="plans-skeleton__features">
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="120px" height="14px" />
                </div>
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="90px" height="14px" />
                </div>
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="105px" height="14px" />
                </div>
              </div>
              <mds-skeleton-bone class="plans-skeleton__action">
                <div class="plans-skeleton__action-button">
                  <mds-button>
                    {{ 'Buy now' | translate | titlecase }}
                  </mds-button>
                </div>
              </mds-skeleton-bone>
            </div>
          </div>
        }
      </div>
    </mds-skeleton>
  }

  <div class="pricing__note">
    @if ((currencyCode$ | async) === null) {
      <mds-skeleton-bone width="242px" height="14px" />
    } @else {
      {{ 'All prices are in [currencyCode]' | translate: {currencyCode: currencyCode$ | async} }}
      @if (showPricingYearly) {
        <span>, {{ 'Billed yearly' | translate | lowercase }} </span>
      }
    }
  </div>

  <ng-template #skeleton>
    <mds-skeleton isAnimated>
      <div class="plans-skeleton">
        @for (skeleton of skeletons; track $index) {
          <div class="plans-skeleton__item">
            <div class="plans-skeleton__card">
              <mds-skeleton-bone class="plans-skeleton__bone plans-skeleton__title" />
              <div class="plans-skeleton__price">
                <mds-skeleton-bone width="70px" height="32px" />
              </div>
              <div class="plans-skeleton__features">
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="120px" height="14px" />
                </div>
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="90px" height="14px" />
                </div>
                <div class="plans-skeleton__feature">
                  <mds-skeleton-bone width="105px" height="14px" />
                </div>
              </div>
              <mds-skeleton-bone class="plans-skeleton__action">
                <div class="plans-skeleton__action-button">
                  <mds-button>
                    {{ 'Buy now' | translate | titlecase }}
                  </mds-button>
                </div>
              </mds-skeleton-bone>
            </div>
          </div>
        }
      </div>
    </mds-skeleton>
  </ng-template>
</div>
