import {NgIf, NgTemplateOutlet} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, Input} from '@angular/core';

export type IMdsSkeletonBorderRadius = 'default' | 'rounded' | 'circle';

@Component({
  selector: 'mds-skeleton-bone',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
  templateUrl: './skeleton-bone.component.html',
  styleUrls: ['./skeleton-bone.component.scss'],
  host: {
    '[style.width]': 'width',
    '[style.height]': 'height',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsSkeletonBoneComponent {
  @Input() borderRadius: IMdsSkeletonBorderRadius = 'default';
  @Input({transform: styleWidthAttribute}) width?: number | string;
  @Input({transform: styleWidthAttribute}) height?: number | string;
  @Input({transform: booleanAttribute}) animated = false;
}

function styleWidthAttribute(value: string | number): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}
