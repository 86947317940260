<div
  class="dialog-root"
  [ngClass]="{'dialog-root--no-padding-on-small-vp': noPaddingOnSmallViewport}"
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp($event)"
  (keydown.escape)="clickBackdrop($event)"
  role="button"
  tabIndex="0"
  cdkTrapFocus
  cdkTrapFocusAutoCapture
>
  <div
    class="dialog-root__wrapper"
    [style.transform-origin]="transformOrigin"
    [@dialogContainer]="animationState"
    [@.disabled]="animationDisabled"
    (@dialogContainer.start)="onAnimationStart($event)"
    (@dialogContainer.done)="onAnimationDone($event)"
  >
    <ng-template cdkPortalOutlet />
  </div>
</div>
