import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BillingPeriod} from '@ps/pricing-domain';

@Component({
  selector: 'px-pricing-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PeriodSelectorComponent {
  readonly BillingPeriod = BillingPeriod;

  @Input() disabled = false;
  @Input() value: BillingPeriod = BillingPeriod.MONTHLY;
  @Output() valueChange = new EventEmitter<BillingPeriod>();

  changePeriod(toggleValue: boolean): void {
    this.value = toggleValue ? BillingPeriod.YEARLY : BillingPeriod.MONTHLY;
    this.valueChange.emit(this.value);
  }
}
