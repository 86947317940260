import {inject, Injectable} from '@angular/core';
import dayjs from 'dayjs';
import {Languages} from '@px/shared/env';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {IUserDataGql} from '../../interfaces/user-gql-data';
import {IUser} from '../../interfaces/user';
import {TransitionTypeId} from '../../enums/transition-type.enum';
import {ICustomerPreferences} from '../../interfaces/customer-preferences-gql.interface';

@Injectable({providedIn: 'root'})
export class AuthGqlAdapterService {
  private readonly productFamily = inject(PRODUCT_FAMILY);

  // todo: split up between projects
  userDataGqlToUser(
    customerPreferences: ICustomerPreferences,
    userData: IUserDataGql,
    isFirstLogin: boolean,
    no_tracking = false
  ): IUser {
    let is_trial = false;
    let user_is_tier_3 = false;
    let statistics = {
      activeCount: 0,
      deactivatedCount: 0,
      publishedActiveCount: 0,
      publishedDeactivatedCount: 0,
      samplePublishedCount: 0,
      sampleCount: 0,
    };

    const billingInfo = userData.myAccount.billingInfo;

    if (this.productFamily === ProductFamily.PSS) {
      user_is_tier_3 = billingInfo?.combinedProductLimits.PSS.slideshowCountLimit === -1;
      is_trial = billingInfo?.combinedProductLimits.PSS.isTrial;
      statistics = {
        activeCount: userData.mySlideshowsStatistics.activeSlideshowsCount,
        deactivatedCount: userData.mySlideshowsStatistics.deactivatedSlideshowsCount,
        publishedActiveCount: userData.mySlideshowsStatistics.publishedActiveSlideshowsCount,
        publishedDeactivatedCount: userData.mySlideshowsStatistics.publishedDeactivatedSlideshowsCount,
        samplePublishedCount: userData.mySlideshowsStatistics.samplePublishedSlideshowsCount,
        sampleCount: userData.mySlideshowsStatistics.sampleSlideshowsCount,
      };
    } else if (this.productFamily === ProductFamily.PSF) {
      user_is_tier_3 = billingInfo?.combinedProductLimits.PSF.galleriesCountLimit === -1 || true;
      is_trial = billingInfo?.combinedProductLimits.PSF.isTrial || true;
      statistics = {
        activeCount: userData.myGalleriesStatistics?.activeGalleriesCount,
        deactivatedCount: userData.myGalleriesStatistics?.deactivatedGalleriesCount,
        publishedActiveCount: userData.myGalleriesStatistics?.publishedActiveGalleriesCount,
        publishedDeactivatedCount: userData.myGalleriesStatistics?.publishedDeactivatedGalleriesCount,
        samplePublishedCount: userData.myGalleriesStatistics?.samplePublishedGalleriesCount,
        sampleCount: userData.myGalleriesStatistics?.sampleGalleriesCount,
      };
    }

    return {
      is_trial,
      // all collections existed and deleted and sample content
      // after sample deleted it's not included here
      slide_show_count: statistics.activeCount + statistics.deactivatedCount,
      // all published collections existed and deleted
      published_media_collections_present: statistics.publishedActiveCount + statistics.publishedDeactivatedCount,
      // all existed collections
      total_media_collections_present: statistics.activeCount,
      // all existed sample content collections
      total_sample_content_media_collections_present: statistics.sampleCount,
      // all existed published sample content collections
      total_published_sample_content_media_collections_present: statistics.samplePublishedCount,

      display_name: customerPreferences.displayName,
      first_seen: dayjs(customerPreferences.dateCreated).unix(),
      beacon_signature: userData.myAccount.beaconSignature ?? '',
      user_plan_id: userData.customerSubscriptions[0]?.package.products[0]?.price.product.id ?? '',
      user_id: userData.myAccount.pxId, // there was PIC user id. But when PIC will be removed, we will not have it
      custom_domain: customerPreferences.customDomain ?? '',
      language: customerPreferences.language as Languages,
      user_plan_name: userData.customerSubscriptions[0]?.package.products[0]?.price.product.name ?? '',
      created_at: dayjs(userData.myAccount.dateCreated).unix(),
      email: userData.myAccount.email,
      user_px_id: userData.myAccount.pxId,
      customer_first_login: !is_trial && isFirstLogin,
      trial_user_first_login: is_trial && isFirstLogin,
      website: userData.myAccount.profile.websiteUrl ?? '',
      is_sharpen: customerPreferences.isSharpeningOn,
      ga_user_code: customerPreferences.gaCode ?? '',
      studio_name: userData.myAccount.profile.studioName,
      full_name: customerPreferences.displayName,
      user_is_tier_3,
      no_tracking,

      user_type_hash: '', // not used in GQL
      hash: '', // not used
      id: userData.myAccount.pxId, // not used
      oauth_code_login: true, // not used

      // PSF
      studio_logo: {
        light_logo: customerPreferences.studioLogo.find(item => item.type === 'LIGHT')?.url,
        dark_logo: customerPreferences.studioLogo.find(item => item.type === 'DARK')?.url,
      },

      // PSS
      business_logo: customerPreferences.studioLogo[0]?.url ?? '',
      video_download_client_enabled: billingInfo?.combinedProductLimits.PSS.isVideoDownloadClientEnabled || false,
      transition_duration: customerPreferences.metaData.lastChosenTransitionDuration ?? 30,
      last_transition_id: customerPreferences.metaData.lastChosenTransitionType ?? TransitionTypeId.CROSS_FADE,
      tpp_shown: customerPreferences.metaData.tpp_shown ?? false,
      download_video_allowed: billingInfo?.combinedProductLimits.PSS.isVideoDownloadPhotographerEnabled || false,
      download_video_4k_allowed: billingInfo?.combinedProductLimits.PSS.isVideo4kAllowed || false,
    };
  }
}
