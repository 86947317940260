<div class="plan-card" [ngClass]="{'plan-card--highlighted': highlighted}">
  @if (promoTitle) {
    <span class="plan-card__promo-title">{{ promoTitle }}</span>
  }
  <div class="plan-card__body">
    <h3 class="plan-card__title">{{ title }}</h3>
    @if (
      {
        monthly: priceMonthly | currency: currencyCode : 'symbol-narrow' : '1.0-0',
      };
      as prices
    ) {
      <div class="plan-card__price price">
        @if (!newPrice) {
          @if (prices.monthly) {
            <p class="price__monthly">
              <span class="price__value">
                <span class="price__currency">
                  <span class="price__symbol">{{ prices.monthly[0] }}</span
                  >{{ prices.monthly.slice(1) }}</span
                >
                <span>/{{ 'mo' | translate }}</span>
              </span>
            </p>
          }
          @if (priceYearly) {
            <p class="price__yearly">
              <span class="price__value">
                <span
                  >{{ priceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}/{{ 'year' | translate }}</span
                >
              </span>
            </p>
          }
        }
        @if (newPrice) {
          @if (prices.monthly) {
            <p class="price__monthly">
              <span class="price__value price__value--old">
                <span class="price__currency">
                  <span class="price__symbol">
                    {{ prices.monthly[0] }}
                  </span>
                  {{ prices.monthly.slice(1) }}
                </span>
              </span>
              <span class="price__value price__value--new">
                <span class="price__currency">
                  <span class="price__symbol"></span>
                  {{ newPriceMonthly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}
                </span>
                <span>/{{ 'mo' | translate }}</span>
              </span>
            </p>
          }
          @if (priceYearly) {
            <p class="price__yearly">
              <span class="price__value" [ngClass]="{'price__value--old': newPrice}">
                <span>{{ priceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}</span>
              </span>
              <span class="price__value price__value--new">
                <span>
                  {{ newPriceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}/{{ 'year' | translate }}
                </span>
              </span>
            </p>
          }
        }
      </div>
    }
    <div class="plan-card__features">
      @for (feature of features; track $index) {
        <px-plan-feature [feature]="feature" />
      }
    </div>
    <div class="plan-card__action">
      @if (subscribed) {
        <div class="plan-card__subscribed-label">
          {{ 'Subscribed' | translate }}
        </div>
      } @else {
        <mds-button
          [hasProgress]="actionPerformed"
          [readonly]="actionBlocked"
          [type]="highlighted ? 'filled' : 'outlined'"
          [color]="highlighted ? 'primary' : 'secondary'"
          [disabled]="actionDisabled"
          (click$)="buy()"
        >
          {{ actionLabel }}
        </mds-button>
      }
    </div>
  </div>
</div>
