import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string, acronyms?: string[]): string;
  transform(value: null | undefined, acronyms?: string[]): null;
  transform(value: string | null | undefined, acronyms: string[] = []): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const acronymsSet = new Set(acronyms);
    const words = value.split(' ');
    const transformedWords = words.map((word, index) => {
      if (acronymsSet.has(word.toUpperCase())) {
        return word.toUpperCase();
      }
      return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase();
    });

    return transformedWords.join(' ');
  }
}
