<div class="plan-feature">
  @for (part of feature?.parts; track $index) {
    <span
      class="plan-feature-part"
      [ngClass]="{'plan-feature-part--with-tooltip': !!part.hint?.length}"
      [puiTooltip]="tooltipTmpl"
      [puiTooltipDisabled]="!part.hint?.length"
      [puiTooltipShowDelay]="10"
    >
      {{ part.text }}
      <ng-template #tooltipTmpl>
        @if (part.hint?.length) {
          <ul class="plan-feature__tooltip">
            @for (feature of part.hint; track $index) {
              <li>{{ feature }}</li>
            }
          </ul>
        }
      </ng-template>
    </span>
  }
</div>
