import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, numberAttribute} from '@angular/core';
import {MdsIconComponent} from '@pui/components/icon-core';

@Component({
  selector: 'mds-spinner',
  standalone: true,
  imports: [MdsIconComponent],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsSpinnerComponent implements OnInit {
  private readonly elementRef = inject(ElementRef);

  @Input({transform: numberAttribute}) diameter = 16;

  ngOnInit(): void {
    const element = this.elementRef.nativeElement as HTMLElement;

    const variable = '--mds-comp-spinner-size';
    element.style.setProperty(variable, `${this.diameter}px`);
  }
}
