<div class="c-snackbar__inner">
  @if (icon) {
    <mds-icon class="c-snackbar__icon" [icon]="icon" />
  }
  <div class="c-snackbar__body">
    {{ text }}
  </div>
  <div class="c-snackbar__actions c-snackbar__actions--buttons">
    @if (action) {
      <mds-button (click$)="call()">
        {{ action }}
        @if (icon) {
          <mds-icon [icon]="icon" />
        }
      </mds-button>
    }
    @if (!hideCloseButton) {
      <mds-button class="c-snackbar__close-btn" variant="fab" (click$)="close()">
        <mds-icon icon="aaClose" />
      </mds-button>
    }
  </div>
</div>
