<div class="subscription-activation-waiting">
  <div class="subscription-activation-waiting__header">
    <div class="subscription-activation-waiting__logo">
      <mds-icon icon="caPixelluLogo" />
    </div>
  </div>

  @switch (bodyView) {
    @case (BodyView.WAITING) {
      <div class="subscription-activation-waiting__body" @fadeIn>
        <div class="subscription-activation-waiting__title">{{ 'Processing upgrade' | translate }}...</div>
        @if (isWaitingSubscription) {
          <div class="subscription-activation-waiting__loader">
            <mds-spinner diameter="24" />
          </div>
        }
        <div class="subscription-activation-waiting__text">
          @if (passedMaxWaitingTime$ | async) {
            {{ 'Your request is still being processed and may take a few more moments' | translate }}. <br /><br />
            {{ 'Thank you for your patience' | translate }}.
          } @else {
            {{ 'It may be several minutes for the processing of your upgrade to complete' | translate }}.
          }
        </div>
      </div>
    }
    @case (BodyView.FINISHED) {
      <div class="subscription-activation-waiting__body" @fadeIn>
        <mds-confirmation class="subscription-activation-waiting__check" />
        <div class="subscription-activation-waiting__title subscription-activation-waiting__title--finished">
          {{ 'Upgrade is complete' | translate }}
        </div>
        <div class="subscription-activation-waiting__text">
          {{ 'Thank you for your purchase' | translate }}
        </div>
        @if (activationAdditionalSuccessText) {
          <div class="subscription-activation-waiting__text">
            {{ activationAdditionalSuccessText }}
          </div>
        }
        <div class="subscription-activation-waiting__actions">
          <button (click)="handleActionClick()">
            {{ subscriptionActivatedActionBtnText }}
          </button>
        </div>
      </div>
    }
  }
</div>
