import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  MdsIcon,
  MdsIconComponent,
  mdsProvideIcons,
  pxChrome,
  pxEdge,
  pxFirefox,
  pxPssLogoRounded,
  pxSafari,
} from '@pui/components/icon-core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'px-unsupported-browser',
  imports: [MdsIconComponent, TranslateModule],
  providers: [mdsProvideIcons({pxChrome, pxEdge, pxFirefox, pxSafari, pxPssLogoRounded})],
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowserComponent {
  @Input() appLogo: MdsIcon = 'pxEmptyIcon';

  get icon(): MdsIcon {
    return this.appLogo;
  }
}
