import {Injectable} from '@angular/core';
import {IUser, IUserDataGql, TransitionTypeId} from '@px/shared/api';
import dayjs from 'dayjs';
import {Languages} from '@px/shared/env';

@Injectable({
  providedIn: 'root',
})
export class AuthGqlAdapterService {
  userDataGqlToUser(userData: IUserDataGql): IUser {
    return {
      oauth_code_login: true, // not used
      published_media_collections_present: 371, // TODO in collections MS
      video_download_client_enabled:
        userData.customerSubscriptions[0]?.package.products[0]?.price.product.metaData
          .PSS_VIDEO_DOWNLOAD_CLIENT_ENABLED === '1',
      transition_duration: userData.customerPreferences.metaData.lastChosenTransitionDuration ?? 30,
      display_name: userData.customerPreferences.displayName,
      first_seen: dayjs(userData.myAccount.dateCreated).unix(),
      beacon_signature: userData.myAccount.beaconSignature ?? '',
      total_media_collections_present: 565, // TODO in collections MS
      slide_show_count: 500, // same as above
      total_sample_content_media_collections_present: 0, // TODO in collections MS
      total_published_sample_content_media_collections_present: 0, // TODO in collections MS
      user_plan_id: userData.customerSubscriptions[0]?.package.products[0]?.price.product.id ?? '',
      user_id: userData.myAccount.pxId, // PIC user id. Should not be used
      custom_domain: userData.customerPreferences.customDomain ?? '',
      language: userData.customerPreferences.language as Languages,
      user_plan_name: userData.customerSubscriptions[0]?.package.products[0]?.price.product.name ?? '',
      user_type_hash: '', // not used in GQL
      created_at: dayjs(userData.myAccount.dateCreated).unix(),
      user_is_tier_3:
        userData.customerSubscriptions[0]?.package.products[0]?.price.product.metaData.PSS_SLIDESHOW_COUNT === '-1',
      last_transition_id: userData.customerPreferences.metaData.lastChosenTransitionType ?? TransitionTypeId.CROSS_FADE,
      tpp_shown: userData.customerPreferences.metaData.tpp_shown ?? false,
      download_video_allowed:
        userData.customerSubscriptions[0]?.package.products[0]?.price.product.metaData
          .PSS_VIDEO_DOWNLOAD_CLIENT_ENABLED === '1',
      download_video_4k_allowed:
        userData.customerSubscriptions[0]?.package.products[0]?.price.product.metaData.PSS_VIDEO_4K_ALLOWED === '1',
      email: userData.myAccount.email,
      user_px_id: userData.myAccount.pxId,
      customer_first_login: false, // TODO in preferences MS?
      trial_user_first_login: false, // TODO in preferences MS?

      website: userData.myAccount.profile.websiteUrl ?? '',
      is_sharpen: userData.customerPreferences.isSharpeningOn,
      hash: '', // not used
      ga_user_code: userData.customerPreferences.gaCode ?? '',
      studio_name: userData.myAccount.profile.studioName,
      business_logo: userData.customerPreferences.studioLogo[0]?.url ?? '',
      studio_logo: {}, // not used
      full_name: userData.customerPreferences.displayName,
      no_tracking: false, // until auto tests in prod
      id: userData.myAccount.pxId, // not used
      is_trial: userData.customerSubscriptions[0]?.package.products[0]?.price.product.metaData.PSS_IS_TRIAL === '1',
    };
  }
}
