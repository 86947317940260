import {Action, ActionReducerMap, createReducer, on} from '@ngrx/store';

import * as AuthActions from './auth.actions';
import {AuthStatusState, LoginPageState} from '../../shared/models/user.model';
import {AuthState} from './auth.state';

export const authReducers: ActionReducerMap<AuthState> = {
  statusState: StatusStateReducer,
  loginPageState: LoginPageReducer,
};

export const initialAuthStatusState: AuthStatusState = {
  isAuthenticated: null,
  user: null,
  s3Signature: {
    credentials: null,
    fresh: false,
    user_id: null,
  },
};

export const initialLoginPageState: LoginPageState = {
  errorMessage: null,
  pendingLogin: false,
};

const statusStateReducer = createReducer(
  initialAuthStatusState,
  on(AuthActions.LogInSuccess, (state: AuthStatusState, {user}) => {
    return {...state, isAuthenticated: true, user: user};
  }),
  on(AuthActions.LogInFailure, (state: AuthStatusState) => {
    return {...state, isAuthenticated: false};
  }),
  on(AuthActions.UpdateUserSuccess, (state: AuthStatusState, {user}) => {
    return {...state, user: {...state.user, ...user}};
  }),
  on(AuthActions.LogOut, (state: AuthStatusState) => {
    return {...initialAuthStatusState};
  })
);

export function StatusStateReducer(state: AuthStatusState | undefined, action: Action) {
  return statusStateReducer(state, action);
}

const loginPageReducer = createReducer(
  initialLoginPageState,
  on(AuthActions.LogInSuccess, (state: LoginPageState, {user}) => {
    return {...state, errorMessage: null, pendingLogin: true};
  }),
  on(AuthActions.LogInFailure, (state: LoginPageState, {error}) => {
    return {...state, errorMessage: error, pendingLogin: false};
  }),
  on(AuthActions.ResetError, (state: LoginPageState) => {
    return {...state, errorMessage: null, pendingLogin: false};
  })
);

export function LoginPageReducer(state: LoginPageState | undefined, action: Action) {
  return loginPageReducer(state, action);
}
