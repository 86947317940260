import {enableProdMode} from '@angular/core';

import {bootstrapApplication} from '@angular/platform-browser';
import {IPSSPlatformConfig, PSSPlatformEnvironment} from '@px/pss/platform-env';
import {IApiResponse} from '@px/shared/api';
import {PlatformEnvironment} from '@px/shared/env';
import {plainToInstance} from 'class-transformer';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

(async (): Promise<void> => {
  let platformEnvironment: PSSPlatformEnvironment;

  try {
    const env: IPSSPlatformConfig = await fetch(
      `/assets/configs/environment.json?${PSSPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const overrides: Record<string, IPSSPlatformConfig> = await fetch(
      `/assets/configs/overrides.json?${PSSPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const platformConfig = Object.assign(env, overrides ?? {});

    if (PSSPlatformEnvironment.IS_DEV) {
      platformEnvironment = plainToInstance(PSSPlatformEnvironment, platformConfig);
    } else {
      try {
        const featureFlags: IApiResponse<Partial<IPSSPlatformConfig>> = await fetch(
          new URL('/feature-flags/', platformConfig.API_URL).toString(),
          {headers: {'x-px-scope': platformConfig.SCOPE}}
        ).then(res => res.json());

        platformEnvironment = plainToInstance(
          PSSPlatformEnvironment,
          Object.assign(platformConfig, {
            FEATURES: Object.assign(platformConfig.FEATURES, featureFlags.data.FEATURES),
            FEATURE_CONFIG: Object.assign(platformConfig.FEATURE_CONFIG, featureFlags.data.FEATURE_CONFIG ?? {}),
            production: environment.production,
          })
        );
      } catch (e) {
        console.warn(e);

        platformEnvironment = plainToInstance(PSSPlatformEnvironment, platformConfig);
      }
    }
  } catch (e) {
    console.warn('USES FALLBACK CONFIG');
    console.warn(e);
    platformEnvironment = plainToInstance(PSSPlatformEnvironment, {});
  }

  appConfig.providers.push(
    {provide: PlatformEnvironment, useValue: platformEnvironment},
    {provide: PSSPlatformEnvironment, useValue: platformEnvironment}
  );

  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
})();
