import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IPSSAuthService, ISaveLastTransitionPicInput} from '../models/auth-service.interface';
import {
  AuthGqlDataService,
  IApiResponse as ApiResponse,
  IApiResponse,
  IUser,
  PreferencesGqlService,
} from '@px/shared/api';
import {AuthGqlAdapterService} from './auth-gql-adapter.service';
import {map} from 'rxjs/operators';
import {PSSPlatformEnvironment} from '../../platform-environment';
import {PSSPlatformFeatures} from '../../core/interfaces/platform-config';
import {AuthService} from './auth.service';

// TODO: remove picAuthService usage when PIC will be removed
@Injectable()
export class PSSAuthFacade implements IPSSAuthService {
  private readonly authGqlDataService = inject(AuthGqlDataService);
  private readonly authGqlAdapter = inject(AuthGqlAdapterService);
  private readonly picAuthService = new AuthService();
  private readonly preferencesService = inject(PreferencesGqlService);
  private readonly platform = inject(PSSPlatformEnvironment);

  private get gqlAtAllPages(): boolean {
    return (
      this.platform.hasFeature(PSSPlatformFeatures.MANAGER_GRAPHQL_API) &&
      this.platform.hasFeature(PSSPlatformFeatures.EDITOR_GRAPHQL_API) &&
      this.platform.hasFeature(PSSPlatformFeatures.PREFERENCES_GRAPHQL_API) &&
      this.platform.hasFeature(PSSPlatformFeatures.SLIDESHOW_STYLE_PAGE_GRAPHQL_API)
    );
  }

  logInCheck(): Observable<IApiResponse<IUser>> {
    return this.authGqlDataService.getUser().pipe(
      map(user => {
        return {
          data: this.authGqlAdapter.userDataGqlToUser(user),
          success: true,
          message: 'success',
        };
      })
    );
  }

  // Used for authorize user on PIC. So if some page has no graphql api, it will authorize user on PIC
  jwtAuthorize(payload: Record<string, unknown>, scope?: string): Observable<IApiResponse<{user: Pick<IUser, 'id'>}>> {
    return this.gqlAtAllPages
      ? of({data: {user: {id: 1}}, success: true, message: 'success'}) // Result is not used in PSS, so we can mock it
      : this.picAuthService.jwtAuthorize(payload, scope);
  }

  // Just to interface implementation. Used by getting s3 policy by PIC. Not used in PSS
  signS3(): any {
    throw new Error('Method not implemented.');
  }

  // Just to interface implementation. Not used in PSS
  jwtAuthorizeCloudProofing(payload: Record<string, unknown>): Observable<void> {
    throw new Error('Method not implemented.');
  }

  // Just to interface implementation. Used for logout on PIC
  logOut(): Observable<void> {
    return this.gqlAtAllPages ? of(undefined) : this.picAuthService.logOut();
  }
  //Just to interface implementation. Used for clear user cache on PIC
  dropAuthCache(): void {
    return this.gqlAtAllPages ? undefined : this.picAuthService.dropAuthCache();
  }

  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<ApiResponse> {
    const inputMetaData = {
      lastChosenTransitionType: data.transition_id,
      lastChosenTransitionDuration: data.transition_duration,
    };

    return this.platform.hasFeature(PSSPlatformFeatures.EDITOR_GRAPHQL_API)
      ? this.preferencesService.save({meta_data: inputMetaData})
      : this.picAuthService.saveLastTransition(data);
  }
}
