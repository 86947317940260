import {Inject, Injectable} from '@angular/core';
import {IPhoto, ISegment, ISlideshow, IUser, SlideshowState} from '@px/shared/api';
import {ISize} from '@px/shared-utils-aspect-ratio';
import {ISlideshowTheme} from '@px/legacy/feature-theme-player';
import {DATA_ADAPTER_CONFIG} from '../tokens/data-adapter.token';
import {IDataAdapterConfig} from '../interfaces/data-adapter-config.interface';
import {IntermediateImageName, MinScreenIntermidiateSize} from '@px/legacy/feature-slideshow-player-core';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {IMdsDialogConfig} from '@pui/components/dialog';
import {IPlayerImageSourceInfo, IWebPlayerDialogData} from '@px/pss/web-player';
import {MediaPlayerSizeEnum} from '@pui/components/media-player';
import {CroppingFacadeService} from '@px/pss-cropping';
import {ISlideEditorModalPhoto} from '@px/pss/feature-slide-editor';

@Injectable()
export class SlideshowDataAdapterService {
  private readonly SHARP_RADIUS = 1;
  private readonly SHARP_AMOUNT_DEFAULT = 50;
  private readonly SHARP_AMOUNT_DPR_1 = 45;
  constructor(
    @Inject(DATA_ADAPTER_CONFIG) private readonly dataAdapterConfig: IDataAdapterConfig,
    @Inject(WINDOW_TOKEN) private readonly window: Window,
    private readonly croppingFacade: CroppingFacadeService
  ) {}

  static slideshowHasExpiredSongs(slideshow: ISlideshow<number | string>): boolean {
    const slideshowSongsExpireTime = SlideshowDataAdapterService.getSlideshowSongsExpireTime(
      slideshow.segments,
      slideshow.common_data?.state ?? null
    );

    return slideshowSongsExpireTime > 0 ? slideshowSongsExpireTime <= new Date().getTime() : false;
  }

  static getSlideshowSongsExpireTime(
    slideshowSegments: ISegment<number | string>[],
    slideshowState: SlideshowState | null
  ): number {
    let slideshowSongWillExpire = 0;

    for (const segment of slideshowSegments) {
      if (segment.audio && slideshowState !== SlideshowState.PUBLISHED) {
        if (segment.audio?.expired) {
          slideshowSongWillExpire = new Date().getTime();
        } else if (segment.audio?.expires_at) {
          if (slideshowSongWillExpire > 0) {
            slideshowSongWillExpire = Math.min(
              slideshowSongWillExpire,
              segment.audio?.expires_at + new Date().getTimezoneOffset() * 60000
            );
          } else {
            slideshowSongWillExpire = segment.audio?.expires_at + new Date().getTimezoneOffset() * 60000;
          }
        }
      }
    }

    return slideshowSongWillExpire;
  }

  private mapSlideshowForQuickPreviewPlayer<T = number | string>(
    plainSlideshow: ISlideshow<T>,
    selectedPhotoId: T | undefined
  ): ISlideshow<T> {
    const filterSegmentsBySelectedPhotoId = (segments: ISegment<T>[]): ISegment<T>[] =>
      segments.filter(segment => !selectedPhotoId || segment.photos.some(photo => photo.id === selectedPhotoId));

    const updatePhotoPaths = (photos: IPhoto<T>[]): IPhoto<T>[] =>
      photos.map(photo => ({
        ...photo,
        image_path: photo.image_path ?? photo.thumbnail,
      }));

    const segments = filterSegmentsBySelectedPhotoId(plainSlideshow.segments).map(segment => ({
      ...segment,
      photos: updatePhotoPaths(segment.photos),
    }));

    return {...plainSlideshow, segments};
  }

  private getUserId(slideshow: ISlideshow<number | string>, user: IUser): string | number {
    return slideshow.original_user_id || user.user_id;
  }

  private getFeaturedImageCropParam(slideshow: ISlideshow): string {
    if (!slideshow?.aspect_ratio || !slideshow.common_data?.featured_image) {
      return '';
    }
    const featuredImage = slideshow.common_data.featured_image;
    const featuredPhoto = slideshow.segments.flatMap(s => s.photos).find(p => p.id === featuredImage.id);
    const croppingData = featuredPhoto?.cropped && featuredPhoto.cropping_data[slideshow.aspect_ratio];
    return croppingData ? this.croppingFacade.createCropParameter(croppingData) : '';
  }

  private buildImageUrlParams(
    slideshow: ISlideshow<number | string>,
    size: ISize,
    dpr: number,
    userTypeHash?: string,
    isSharpen?: boolean,
    croppingDisabled?: boolean
  ): string {
    const croppingParam = this.getFeaturedImageCropParam(slideshow as ISlideshow);

    let params = userTypeHash ? `uh=${userTypeHash}` : 'uh';
    params += !croppingDisabled && croppingParam ? `&${croppingParam}` : '';
    params += `&width=${size.width}&height=${size.height}`;
    params += isSharpen
      ? `&sharp_radius=${this.SHARP_RADIUS}&sharp_amount=${
          dpr === 1 ? this.SHARP_AMOUNT_DPR_1 : this.SHARP_AMOUNT_DEFAULT
        }`
      : '';
    return params;
  }

  private getIntermediateImageName(size: ISize, dpr: number): IntermediateImageName {
    const isVertical = size.height > size.width;
    if (
      (!isVertical &&
        (size.width * dpr > MinScreenIntermidiateSize.WIDTH || size.height * dpr > MinScreenIntermidiateSize.HEIGHT)) ||
      (isVertical &&
        (size.width * dpr > MinScreenIntermidiateSize.HEIGHT || size.height * dpr > MinScreenIntermidiateSize.WIDTH))
    ) {
      return IntermediateImageName.UHD;
    }
    return IntermediateImageName.FHD;
  }

  getFeaturedImageURL(slideshow: ISlideshow<number | string>, user: IUser, size?: ISize): string {
    const featuredImage = slideshow.common_data.featured_image;
    const dpr = this.getDevicePixelRatio();
    const width = size?.width ?? this.window.innerWidth;
    const height = size?.height ?? this.window.innerHeight;
    const intermediateImageName = this.getIntermediateImageName({width, height}, dpr);

    if (featuredImage.image_link2k && featuredImage.image_link4k) {
      const links =
        intermediateImageName === IntermediateImageName.UHD ? featuredImage.image_link4k : featuredImage.image_link2k;
      const path = user.is_trial ? links.watermarked : links.pure;
      const url = new URL(path);
      url.searchParams.set('width', width.toString());
      url.searchParams.set('height', height.toString());
      return url.toString();
    }

    const userId = this.getUserId(slideshow, user);
    if (!userId) {
      return null;
    }

    const imageUrlHParam = this.buildImageUrlParams(
      slideshow,
      {width, height},
      dpr,
      user.user_type_hash,
      user.is_sharpen,
      true
    );

    const baseImagesUrl = `${this.dataAdapterConfig.IMAGES_PATH}${this.dataAdapterConfig.SCOPE}/${slideshow.common_data.featured_image.active_storage_service}/`;
    return `${baseImagesUrl}${intermediateImageName}/${dpr}x/u${userId}/${slideshow.common_data.featured_image.image_path}?${imageUrlHParam}`;
  }

  getDevicePixelRatio(): number {
    let ratio = this.window.devicePixelRatio || 1;

    ratio = Math.min(ratio, this.dataAdapterConfig.MAX_DPR);
    ratio = Math.round(ratio * 100) / 100;

    return Math.max(ratio, 1);
  }

  audioStartIsValidAndAboveZero = (audio_start: number): boolean => Number.isFinite(audio_start) && audio_start > 0;

  getSlideshowTheme(
    slideshow: ISlideshow<number | string>,
    isDownloadEnabled = false,
    isSharedLinksEnabled = false,
    facebookId?: string,
    isProtected?: boolean
  ): ISlideshowTheme {
    return {
      encodedName: slideshow.encoded_name,
      hasExpiredSongs: SlideshowDataAdapterService.slideshowHasExpiredSongs(slideshow),
      hasDownloadButton: slideshow.common_data.is_download_button,
      isDownloadProtected: slideshow.common_data.download_protected,

      isSharedLinksEnabled,
      facebookId,
      isProtected,
      isDownloadEnabled,
      video1080p: slideshow.video_1080p,
      name: slideshow.name,
      common_data: {
        featured_image: {
          id: slideshow.common_data.featured_image.id,
          image_1080p: slideshow.common_data.featured_image.image_1080p,
        },
        event_date: slideshow.common_data.event_date,
        call_to_action: slideshow.common_data.call_to_action,
      },
    };
  }

  getImageRoot(slideshow: ISlideshow<number | string>, user: IUser): Map<string, string> {
    const userId = this.getUserId(slideshow, user);
    if (!userId) {
      return new Map<string, string>();
    }
    const dpr = this.getDevicePixelRatio();
    const width = this.window.innerWidth;
    const height = this.window.innerHeight;
    const intermediateImageName = this.getIntermediateImageName({width, height}, dpr);
    const photos = slideshow?.segments?.length
      ? slideshow.segments.flatMap(s => s.photos)
      : [slideshow.common_data.featured_image];

    return photos.reduce((acc, photo) => {
      let root = `${this.dataAdapterConfig.IMAGES_PATH}${this.dataAdapterConfig.SCOPE}/${photo.active_storage_service}/${intermediateImageName}/${dpr}x/u${userId}/`;

      if (photo.image_link2k && photo.image_link4k) {
        const imageLink =
          intermediateImageName === IntermediateImageName.UHD ? photo.image_link4k.pure : photo.image_link2k.pure;
        root = imageLink.slice(0, imageLink.indexOf(photo.image_path));
      }

      acc.set(photo.image_path, root);
      return acc;
    }, new Map<string, string>());
  }

  getPhotosForSlideEditor(
    slideshow: ISlideshow<number | string>,
    photos: IPhoto[],
    user: IUser
  ): ISlideEditorModalPhoto[] {
    const userId = this.getUserId(slideshow, user);
    const width = this.window.innerWidth;
    const height = this.window.innerHeight;
    const dpr = this.getDevicePixelRatio();
    const intermediateImageName = this.getIntermediateImageName({width, height}, dpr);
    return photos.map(photo => {
      let imageRoot = userId
        ? `${this.dataAdapterConfig.IMAGES_PATH}${this.dataAdapterConfig.SCOPE}/${photo.active_storage_service}/${intermediateImageName}/${dpr}x/u${userId}/`
        : '';

      if (photo.image_link2k && photo.image_link4k) {
        const link =
          intermediateImageName === IntermediateImageName.UHD ? photo.image_link4k.pure : photo.image_link2k.pure;
        imageRoot = link.slice(0, link.indexOf(photo.image_path));
      }

      return {
        ...photo,
        imageRoot,
      };
    });
  }

  getImageSourceInfo(slideshow: ISlideshow<number | string>, user: IUser): IPlayerImageSourceInfo {
    const dpr = this.getDevicePixelRatio();
    const sharpenAmount = dpr === 1 ? 45 : 50;
    const sharpening = user.is_sharpen ? {radius: 1, amount: sharpenAmount} : undefined;

    return {
      root: this.getImageRoot(slideshow, user),
      hash: user?.user_type_hash ?? 'uh',
      sharpening,
      withWatermark: user.is_trial,
    };
  }

  getConfigForQuickPreviewPlayer(
    slideshow: ISlideshow<number | string>,
    user: IUser,
    selectedPhotoId?: number | string
  ): IMdsDialogConfig<IWebPlayerDialogData> {
    const imageSourceInfo: IPlayerImageSourceInfo = this.getImageSourceInfo(slideshow, user);

    return {
      data: {
        slideshow: this.mapSlideshowForQuickPreviewPlayer(slideshow, selectedPhotoId),
        selectedPhotoId,
        title: 'EDITOR_PAGE.LOW_RESOLUTION_PREVIEW_MESSAGE',
        manualPlay: true,
        size: MediaPlayerSizeEnum.SMALL,
        autoHideControls: false,
        showVolumeControls: false,
        showFullscreenControls: false,
        imageSourceInfo,
        applyStyleOptionsBackground: false,
      },
    };
  }
}
