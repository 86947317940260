import {IBillingProductClient} from '../entities/billing-product-client';
import {Observable} from 'rxjs';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface IBillingProductsFacade {
  getProducts(
    productFamily: ProductFamily,
    highlightProduct: ProductFamily,
    highlightProductTitle: string,
    originalProductFamily?: ProductFamily
  ): Observable<IBillingProductClient[]>;
}
