import {ESCAPE} from '@angular/cdk/keycodes';
import {OverlayRef} from '@angular/cdk/overlay';
import {ComponentRef} from '@angular/core';
import {untilDestroyed} from '@ngneat/until-destroy';
import {filter, Observable, Subject, take} from 'rxjs';
import {MdsDialogRootComponent} from '../components/dialog-root/dialog-root.component';
import {IMdsDialogConfig} from './dialog-config.interface';
import {MdsDialogState} from './dialog-state.enum';

export class MdsDialogRef<C, R = unknown> {
  private readonly beforeClosedInternal = new Subject<R | undefined>();
  private readonly afterClosedInternal = new Subject<R | undefined>();
  private result: R | undefined;
  private state = MdsDialogState.OPENING;

  afterOpenedInternal = new Subject<boolean>();
  containerRef?: ComponentRef<MdsDialogRootComponent<C>>;

  get component(): C {
    return this.containerRef?.instance.contentComponent as C;
  }

  constructor(
    private overlayRef: OverlayRef,
    protected config: IMdsDialogConfig
  ) {
    this.overlayRef
      .detachments()
      .pipe(untilDestroyed(this, 'close'))
      .subscribe(() => {
        this.beforeClosedInternal.next(this.result);
        this.beforeClosedInternal.complete();
        this.afterClosedInternal.next(this.result);
        this.afterClosedInternal.complete();
        this.overlayRef.dispose();
      });

    this.overlayRef
      .keydownEvents()
      .pipe(filter(event => event.keyCode === ESCAPE))
      .subscribe(() => {
        if (!config?.escClose) return;
        this.close();
      });
  }

  private finishDialogClose(): void {
    this.state = MdsDialogState.CLOSED;
    this.overlayRef.dispose();
  }

  afterOpened(): Observable<boolean> {
    return this.afterOpenedInternal;
  }

  beforeClosed(): Observable<R | undefined> {
    return this.beforeClosedInternal;
  }

  afterClosed(): Observable<R | undefined> {
    return this.afterClosedInternal;
  }

  getState(): MdsDialogState {
    return this.state;
  }

  setState(state: MdsDialogState): void {
    this.state = state;
  }

  close(dialogResult?: R): void {
    if (this.state === MdsDialogState.OPENING || this.state === MdsDialogState.CLOSING) return;

    this.state = MdsDialogState.CLOSING;
    this.result = dialogResult;

    this.containerRef?.instance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'start'),
        take(1)
      )
      .subscribe(() => {
        this.beforeClosedInternal.next(dialogResult);
        this.beforeClosedInternal.complete();
        this.overlayRef.detachBackdrop();
      });

    this.containerRef?.instance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => {
        this.afterClosedInternal.next(this.result);
        this.afterClosedInternal.complete();
        this.finishDialogClose();
      });

    this.containerRef?.instance.startExitAnimation();
  }

  forceClose(): void {
    this.beforeClosedInternal.complete();
    this.overlayRef.dispose();
  }
}
