import {createSelector} from '@ngrx/store';

import {AppSelectors} from '../../../shared/store/app/app.selectors';
import {getSelectedThumbnailSizeId} from './preferences-utils';

export namespace PreferencesSelectors {
  export const getActualPreferencesState = createSelector(AppSelectors.getPreferencesState, state => {
    return state.actualState;
  });

  export const getSavedPreferencesState = createSelector(AppSelectors.getPreferencesState, state => state.savedState);

  export const getStatusPreferencesState = createSelector(AppSelectors.getPreferencesState, state => state.statusState);

  export const getLoaded = createSelector(getStatusPreferencesState, statusState => statusState.loaded);

  export const getSaving = createSelector(getStatusPreferencesState, statusState => statusState.saving);

  export const getSavingErrors = createSelector(getStatusPreferencesState, statusState => statusState.savingErrors);

  export const getUploading = createSelector(getStatusPreferencesState, statusState => statusState.uploading);

  export const selectSelectedThumbnailSizeId = createSelector(getActualPreferencesState, preferences =>
    getSelectedThumbnailSizeId(preferences.editor_thumbnail_size)
  );
}
