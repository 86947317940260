import {Injectable} from '@angular/core';
import {User} from '../../shared/models/user.model';
import {PssTrackingService} from '@px/pss/feature-tracking';

type Respond = (result: {score: number}) => void;
declare let delightedNps: {survey(payload: Record<string, string | Record<string, string> | Respond>): void};

@Injectable({
  providedIn: 'root',
})
export class DelightedService {
  constructor(private readonly pssTrackingService: PssTrackingService) {}

  init(user: User): void {
    try {
      if (typeof delightedNps !== 'undefined') {
        delightedNps.survey({
          email: user.user_px_id.toString() + '@pxid.pixellu.com', // pxid + ...
          name: user.full_name, // customer name (optional)
          // createdAt: new Date(user.created_at * 1000).toISOString(),  // time subscribed (optional)  ISO8601 format
          properties: {
            // extra context (optional)
            plan: user.user_plan_name, // user plan
            actualEmail: user.email, // real email
          },
          onRespond: result => {
            this.pssTrackingService.setSurveyScore(result.score);
          },
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
