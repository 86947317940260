import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {TranslateManagerService} from '@px/pss/feature-translate';
import {GoogleAnalyticsService} from './auth/services/google-analytics.service';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import {ConnectionStatusFacadeService} from '@px/connection-issue/domain';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {GlobalState} from './shared/store/global/global.state';
import {ConnectionOffline, ConnectionOnline} from './shared/store/global/global.actions';
import {FeatureFlagBodyClassService} from '@px/shared/utils/feature-flag-body-class';
import {RouterOutlet} from '@angular/router';

@UntilDestroy()
@Component({
  imports: [RouterOutlet],
  template: '<router-outlet />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly translateManagerService = inject(TranslateManagerService);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly platform = inject(PSSPlatformEnvironment);
  private readonly connectionStatusFacadeService = inject(ConnectionStatusFacadeService);
  private readonly store$ = inject(Store<GlobalState>);
  private readonly featureFlagBodyClassService = inject(FeatureFlagBodyClassService);

  ngOnInit(): void {
    if (this.platform.GA_ENABLED) {
      this.googleAnalyticsService.init(this.platform.GA_TRACKING_ID);
    }

    this.translateManagerService.initialize();
    this.connectionStatusFacadeService.initialize();
    this.connectionStatusFacadeService.hasConnection$.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      if (status) {
        this.store$.dispatch(new ConnectionOnline());
        return;
      }

      this.store$.dispatch(new ConnectionOffline());
    });
    this.featureFlagBodyClassService.init();
    this.platform.printVersion();
  }
}
