import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {GlobalState} from './global.state';
import {DownloadFile, GlobalActionTypes, OfflineSnackbarHide, OfflineSnackbarShow} from './global.actions';
import {SnackbarComponent} from '../../components/snackbar/snackbar.component';
import {selectOfflineSnackbarRef} from './global.selectors';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import {AppRoutes} from '../../models/routes.enum';
import {PUI_SNACKBAR_SERVICE} from '@pui/components/snackbar';

@Injectable()
export class GlobalEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store<GlobalState>);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private readonly snackBar = inject(PUI_SNACKBAR_SERVICE);
  private readonly platform = inject(PSSPlatformEnvironment);

  redirectUnsupportedBrowser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_UNSUPPORTED_BROWSER),
        tap(() => {
          this.router.navigate(['/unsupported-browser']);
        })
      ),
    {dispatch: false}
  );

  redirectMobileDevice = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_MOBILE_DEVICE),
        tap(() => this.router.navigate([`/${AppRoutes.MOBILE}`]))
      ),
    {dispatch: false}
  );

  ConnectionOffline = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActionTypes.CONNECTION_OFFLINE),
      withLatestFrom(this.store$.select(selectOfflineSnackbarRef)),
      filter(([, snackbarRef]) => snackbarRef === null),
      map(() => {
        const snackBarRef = this.snackBar.open(SnackbarComponent, {
          data: this.translate.instant('THEMES_PREVIEW_PAGE.CONNECTION_PROBLEM_MESSAGE'),
          persistent: true,
          verticalPosition: 'top',
        });
        return new OfflineSnackbarShow(snackBarRef);
      })
    )
  );

  ConnectionOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActionTypes.CONNECTION_ONLINE),
      withLatestFrom(this.store$.select(selectOfflineSnackbarRef)),
      filter(([, snackbarRef]) => snackbarRef !== null),
      map(([, snackbarRef]) => {
        snackbarRef.close();
        return new OfflineSnackbarHide();
      })
    )
  );

  DownloadFile = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.DOWNLOAD_FILE),
        map((action: DownloadFile) => action.url),
        tap(file_url => {
          const url = new URL(file_url, this.platform.CF_URL);
          const link = document.createElement('a');
          link.setAttribute('href', url.toString());
          link.setAttribute('download', 'download');
          link.click();
        })
      ),
    {dispatch: false}
  );
}
