import {inject, Injectable} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import type {ApiObject, IdentifyTraits} from '@rudderstack/analytics-js';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RudderstackFreshService {
  private readonly platform = inject(PlatformEnvironment);

  private isEnabledInternal = false;
  private rudderAnalytics: import('@rudderstack/analytics-js').RudderAnalytics | undefined;

  isEnabled$ = new BehaviorSubject<boolean>(this.isEnabledInternal);

  private get isEnabled(): boolean {
    return this.isEnabledInternal;
  }

  private set isEnabled(value: boolean) {
    this.isEnabledInternal = value;
    this.isEnabled$.next(value);
  }

  async init(): Promise<void> {
    if (this.isEnabled) {
      return;
    }

    if (this.platform.RUDDERSTACK_ENABLED) {
      try {
        const {RudderAnalytics} = await import('@rudderstack/analytics-js');

        this.rudderAnalytics = new RudderAnalytics();

        this.rudderAnalytics.load(this.platform.RUDDERSTACK_WRITE_KEY, this.platform.RUDDERSTACK_DATA_PLANE_URL, {
          logLevel: this.platform.DEBUG ? 'DEBUG' : 'ERROR',
        });

        this.rudderAnalytics.reset();
        this.isEnabled = true;
      } catch (e: unknown) {
        console.error((e as Error).name + ': ' + (e as Error).message);
        this.isEnabled = false;
      }
    }
  }

  track(id: string, action: ApiObject = {}): void {
    try {
      if (this.isEnabled) {
        this.rudderAnalytics?.track(id, action);
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  // there is no super properties in rudderstack, so we just set user properties.
  // Setup super properties in a rudderstack dashboard.
  // https://www.rudderstack.com/docs/destinations/streaming-destinations/mixpanel/#explicitly-setting-people-properties-and-super-properties
  setSuperProperties(properties: ApiObject): void {
    try {
      const userId = this.rudderAnalytics?.getUserId();

      if (this.isEnabled && userId) {
        this.rudderAnalytics?.identify(userId, properties as IdentifyTraits);
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  setUserProperties(properties: ApiObject): void {
    try {
      const userId = this.rudderAnalytics?.getUserId();

      if (this.isEnabled && userId) {
        this.rudderAnalytics?.identify(userId, properties as IdentifyTraits);
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  // there is no increment user properties in rudderstack, so we just set user properties.
  // Setup increment properties in a rudderstack dashboard.
  // https://www.rudderstack.com/docs/destinations/streaming-destinations/mixpanel/#incrementing-events-in-mixpanel-people
  incrementUserProperties(property: string, incrementBy = 1): void {
    try {
      const userId = this.rudderAnalytics?.getUserId();

      if (this.isEnabled && userId && property) {
        let {[property]: previousValue} = this.rudderAnalytics?.getUserTraits() ?? {[property]: 0};

        if (!previousValue) {
          previousValue = 0;
        }

        if (typeof previousValue !== 'number') {
          previousValue = Number(previousValue);
        }

        this.rudderAnalytics?.identify(userId, {
          [property]: previousValue + incrementBy,
        });
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  identify(userId?: string | number): void {
    try {
      this.rudderAnalytics?.reset();

      if (this.isEnabled) {
        this.rudderAnalytics?.identify(userId?.toString() || '');
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }

  destroy(): void {
    try {
      if (this.isEnabled) {
        this.rudderAnalytics?.reset();
      }
    } catch (e: unknown) {
      console.error((e as Error).name + ': ' + (e as Error).message);
    }
  }
}
