import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MdsButtonComponent} from '@pui/components/button';
import {MdsIconComponent} from '@pui/components/icon-core';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import {UiButtonModule} from '@px/ui-components-px-button';
import {SlideshowNameComponent} from './components/slideshow-name/slideshow-name.component';
import {UpgradeReminderComponent} from './components/upgrade-reminder/upgrade-reminder.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    UiButtonModule,
    PuiTooltipDirective,
    MdsButtonComponent,
    MdsIconComponent,
    SlideshowNameComponent,
  ],
  declarations: [UpgradeReminderComponent],
  exports: [SlideshowNameComponent, UpgradeReminderComponent],
})
export class FeaturePageManagerModule {}
