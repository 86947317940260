import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {A11yModule} from '@angular/cdk/a11y';
import {CdkPortalOutlet, ComponentPortal, PortalModule} from '@angular/cdk/portal';
import {NgClass} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  inject,
  ViewChild,
} from '@angular/core';
import {Subject} from 'rxjs';
import {MDS_DIALOG_DATA} from '../../interfaces/dialog.tokens';

@Component({
  selector: 'mds-dialog-root',
  imports: [NgClass, PortalModule, A11yModule],
  templateUrl: './dialog-root.component.html',
  styleUrls: ['./dialog-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dialogContainer', [
      state('void, exit', style({opacity: 0, transform: 'scale(0.95)'})),
      state('enter', style({opacity: 1, transform: 'scale(1)'})),
      transition('* => *', animate('150ms')),
    ]),
  ],
})
export class MdsDialogRootComponent<C> {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly config = inject(MDS_DIALOG_DATA);

  private portalComponentRef: ComponentRef<C> | undefined;

  readonly elementRef = inject(ElementRef);

  @ViewChild(CdkPortalOutlet, {static: true}) cdkPortalOutlet?: CdkPortalOutlet;

  transformOrigin = '';
  animationState: 'void' | 'enter' | 'exit' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  backdropClick$ = new Subject<void>();

  noPaddingOnSmallViewport = false;
  mouseDownInside = false;

  get animationDisabled(): boolean {
    return this.config.disableAnimation ?? false;
  }

  get contentComponent(): C | undefined {
    return this.portalComponentRef?.instance;
  }

  onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  resetAnimation(): void {
    this.animationState = 'void';
    this.cdr.detectChanges();
  }

  startExitAnimation(): void {
    this.animationState = 'exit';
    this.cdr.detectChanges();
  }

  startEnterAnimation(): void {
    this.animationState = 'enter';
    this.cdr.detectChanges();
  }

  onMouseDown($event: MouseEvent): void {
    const target = $event.target as HTMLElement;
    this.mouseDownInside = target.closest('.dialog-root__wrapper') !== null;
  }

  onMouseUp($event: MouseEvent): void {
    if (this.mouseDownInside) {
      return;
    }

    this.clickBackdrop($event);
    this.mouseDownInside = false;
  }

  clickBackdrop($event: MouseEvent | KeyboardEvent | Event): void {
    const target = $event.target as HTMLElement;
    if (!target.classList.contains('dialog-root')) return;
    this.backdropClick$.next();
  }

  attachPortal(portal: ComponentPortal<C>): void {
    if (this.cdkPortalOutlet?.hasAttached()) {
      return;
    }

    this.portalComponentRef = this.cdkPortalOutlet?.attachComponentPortal(portal);
  }
}
