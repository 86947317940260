import {NgModule} from '@angular/core';
import {rudderstackProviders} from '@px/shared/data-access/rudderstack';
import {MixpanelModule} from '@px/shared-data-access-mixpanel';
import {TrackingService} from './services/tracking.service';

@NgModule({
  imports: [MixpanelModule],
  providers: [TrackingService, ...rudderstackProviders],
})
export class FeatureTrackingModule {}
