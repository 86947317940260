import {FeatureConfigBase, IPlatformConfig} from '@px/shared/env';
import {SlideshowState} from '@px/shared/api';
import {PSSThemesFeatureList} from '@px/pss-themes/platform-env';
import {AspectRatio} from '@px/shared-utils-aspect-ratio';
import {TransitionTypeId} from '@px/pss-audio';

export interface IPSSPlatformConfig extends IPlatformConfig<PSSPlatformFeatures, PSSFeatureConfig> {
  SLIDESHOW_STATE_LIST: ISlideshowStateList;
  VIDEO_BACKGROUND_COLOR: IVideoBackgroundColor[];
  IFRAME_SIZES: AspectRatioFrameSizes;
  COMMON_SETTINGS: ICommonSettings;
  USER_FOLDER_ID: number;
  IMAGES_SORT_TYPE_LIST: IImagesSortTypeList;
  MANAGER_SORT_TYPE_LIST: IManagerSortTypeList;
  TRANSITION_TYPE_LIST: ITransitionType[];
  URL_PATTERN: RegExp;
  BUILD_VIDEO_ENABLED: boolean;
  SUBMIT_FEEDBACK: string;
  BEATMATCHING_SEARCH_RANGE: {min: number; max: number};
  MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE: number;
}

export enum PSSPlatformFeatures {
  DUPLICATE_IMAGES = 'DUPLICATE_IMAGES',
  AUDIO_SEARCH_BM_SORT = 'AUDIO_SEARCH_BM_SORT',
  AUTO_CROPPING_HORIZONTAL = 'AUTO_CROPPING_HORIZONTAL',
  DISABLE_UPLOADER_LIMIT = 'DISABLE_UPLOADER_LIMIT',
  WAVEFORM_DEMO = 'WAVEFORM_DEMO',
  PREFERENCES_GRAPHQL_API = 'PREFERENCES_GRAPHQL_API',
  EDITOR_GRAPHQL_API = 'EDITOR_GRAPHQL_API',
  MANAGER_GRAPHQL_API = 'MANAGER_GRAPHQL_API',
  SLIDESHOW_STYLE_PAGE_GRAPHQL_API = 'SLIDESHOW_STYLE_PAGE_GRAPHQL_API',
  JPEG_ONLY_IN_PLAYER = 'JPEG_ONLY_IN_PLAYER',
  AUTHORIZATION_GRAPHQL_API = 'AUTHORIZATION_GRAPHQL_API',
  CLOUDFRONT_IMAGE_LOADING = 'CLOUDFRONT_IMAGE_LOADING',
  PREFERENCES_MDS_MIGRATION = 'PREFERENCES_MDS_MIGRATION',
  MDS_SLIDESHOW_OPTIONS = 'MDS_SLIDESHOW_OPTIONS',
  MDS_TPP_MIGRATION = 'MDS_TPP_MIGRATION',
}

export type PSSFeatureList = keyof typeof PSSPlatformFeatures;
export type PSSFeatureConfig = Record<PSSThemesFeatureList, FeatureConfigBase>;

export interface ISlideshowStateList {
  CREATED: SlideshowState;
  EDITOR: SlideshowState;
  SELECTED_FEATURE_IMAGE: SlideshowState;
  PUBLISHED: SlideshowState;
  CHANGED: SlideshowState;
}

export interface IVideoBackgroundColor {
  id: number;
  title: string;
  value: string;
}

export type AspectRatioFrameSizes = {
  [key in AspectRatio]: IFrameSizes;
};

export interface IFrameSizes {
  min: IFrameSize;
  default: IFrameSize;
}

export interface IFrameSize {
  width: number;
  height: number;
}

export interface ICommonSettings {
  availableMusicTypes: IAvailableMusicTypes;
  availablePhotoTypes: string;
  PHOTO: IPhotoSettings;
  LOGO: ILogo;
  SLIDESHOW_LIMITS: ISlideshowLimits;
  MAX_DPR: number;
  MAX_UPLOAD_FILE_SIZE: number;
  processingImageTime: number;
  checkVideoStatusInterval: number;
  AUTO_SAVE_INTERVAL: number;
  checkViewStatusInterval: number;
  maxUploadStreams: number;
  FRAMES_PER_SEC: number;
  CHANGE_SEGMENT_ANIMATION_TIME: number;
  AUTO_TRANSITION_DURATION: number;
  FADE_TRANSITION_DURATION: IFadeTransitionDuration;
  s3signature: IS3signature;
}

export interface IAvailableMusicTypes {
  mp3: string;
  m4a: string;
  aac: string;
}

export interface IPhotoSettings {
  AVAILABLE_PHOTO_EXTENSIONS: string[];
}

export interface ILogo {
  MIN_HEIGHT: number;
  MIN_THUMB_HEIGHT: number;
  MIN_WIDTH: number;
  AVAILABLE_LOGO_EXTENSIONS: string[];
}

export interface ISlideshowLimits {
  MIN_SLIDE_DURATION: number;
  MIN_SEGMENT_DURATION: number;
  MIN_LENGTH_FROM_START_TO_FIRST_BM_POINT: number;
  MIN_LENGTH_FROM_END_TO_LAST_BM_POINT: number;
  MAX_SEGMENTS: number;
  MAX_DURATION: number;
  MAX_IMAGES: number;
}

export interface IFadeTransitionDuration {
  START: number;
  END: number;
}

export interface IS3signature {
  minExpirationTime: number;
}

export interface IImagesSortTypeList {
  name: IImageSortType;
  date_taken: IImageSortType;
  shuffle: IImageSortType;
  manual: IImageSortType;
}

export interface IImageSortType {
  order: number;
  title: string;
  mpPropName?: string;
  disabled: boolean;
}

export interface IManagerSortTypeList {
  name_asc: IManagerSortType;
  date_created_asc: IManagerSortType;
  date_created_desc: IManagerSortType;
  date_event_asc: IManagerSortType;
  date_event_desc: IManagerSortType;
}

export interface IManagerSortType {
  order: number;
  title: string;
  field: string;
  asc: boolean;
}

export interface ITransitionType {
  id: TransitionTypeId;
  title: string;
  disabled: boolean;
}
