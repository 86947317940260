<div class="mobile-page-wrapper" layout="column" layout-align="center center">
  <div class="logo-wrapper">
    <a routerLink="/" class="icon-logo"> <mds-icon icon="pxPssLogo" /></a>
  </div>
  <div class="center-container text-center" layout="column" layout-align="center center">
    @for (description of descriptions; track $index) {
      <div class="text-line">{{ description | translate }}</div>
    }
    <div class="link-separator"></div>
    <a class="px-link" routerLink="/">{{ 'Visit Slideshow Manager' | translate }}</a>
  </div>
  <div>
    <a [href]="platformEnvironment.PIXELLU_URL" style="display: inline-block; color: #373939">
      <mds-icon icon="caPixelluLogo" />
    </a>
  </div>
</div>
