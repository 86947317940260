import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {AppComponent} from './app-component/app.component';
import {AbTestsModule} from '../shared/angular-ab-tests/module';
import {globalStateReducer} from 'apps/pss/src/app/store/global/global.reducer';
import {GlobalEffects} from 'apps/pss/src/app/store/global/global.effects';

@NgModule({
  imports: [
    RouterModule,

    StoreModule.forFeature('globals', globalStateReducer),
    EffectsModule.forFeature([GlobalEffects]),

    AbTestsModule.forRoot([
      {
        versions: ['old', 'new'],
        // weights: { A: 50, B: 50 }
      },
    ]),
  ],
  declarations: [AppComponent],
  exports: [AppComponent],
})
export class CoreModule {}
