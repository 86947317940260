import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MdsAppLoaderService} from '@mds/components/app-loader';
import {TranslateModule} from '@ngx-translate/core';
import {FeaturePageMobileNotSupportedModule} from '@px/legacy/feature-page-mobile-not-supported';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import {MdsIconComponent, mdsProvideIcons, pxPssLogo} from '@pui/components/icon-core';

import {RouterLink} from '@angular/router';

@Component({
  selector: 'pss-mobile',
  imports: [FeaturePageMobileNotSupportedModule, TranslateModule, MdsIconComponent, RouterLink],
  providers: [MdsAppLoaderService, mdsProvideIcons({pxPssLogo})],
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileComponent implements OnInit {
  private readonly mdsAppLoaderService = inject(MdsAppLoaderService);
  readonly platformEnvironment = inject(PSSPlatformEnvironment);

  readonly descriptions = [
    'Editing slideshows is not supported on mobile devices',
    'LOGIN_PAGE.MOBILE_LOGIN_MESSAGE_PART_TWO',
  ];

  ngOnInit(): void {
    this.mdsAppLoaderService.completeLoading();
  }
}
